// @flow
import { RatingScale, type Company } from "../utils/Constants";
import { Button, Accordion } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "_metronic/_partials/controls/Card";

import {
  formatAmount,
  formatAmountCompact,
  getDatePart,
  mapAnalysisSectionEnumToLabel,
  mapBuyRatingToGradeBadge,
  mapCompanyGrowthRateEnumToLabel,
  mapCompanyMarketCapEnumToLabel,
  mapRatingToGradeBadge,
  reduceHeadingLevels,
  timesAgo,
} from "app/utils/UtilFunctions";
import React, { useEffect } from "react";
import { Badge, Table } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { recordPageView } from "app/utils/APIFunctions";

/**
 * Represents a Company
 * @returns
 */

type Props = {
  company: Company,
};

const getHeader = (company: Company) => {
  return (
    <>
      <img src={company.photo} height={50}></img>
      <span> {company.title}</span>
      {/* <Badge variant="danger">Company</Badge> <span> {company.title}</span> */}
    </>
  );
};

const getFooter = (company: Company) => {
  return (
    <>
      {company.tags &&
        company.tags.split(",").map((tag) => (
          <span id="one" key={tag}>
            <Badge variant="light">{tag}</Badge>
            &nbsp;
          </span>
        ))}

      <span className="small">
        Updated: {getDatePart(company.profile.last_refresh_at)} (
        {timesAgo(company.profile.last_refresh_at)})
      </span>
    </>
  );
};

const CompanyView = ({ company }: Props): React$MixedElement => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Card>
            <CardHeader title={getHeader(company)} />{" "}
            <CardBody>
              <div>{ReactHtmlParser(company.contents)}</div>
              <br></br>

              <div className="row">
                <div className="col-md-6">
                  <Table responsive className="border">
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">Sector</td>
                        <td> {company.profile.sector}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> Industry</td>
                        <td> {company.profile.industry}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> Employees</td>
                        <td> {formatAmount(company.profile.employees)}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> Founded On</td>
                        <td> {company.profile.founded_on}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> CEO</td>
                        <td> {company.profile.ceo}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> Location</td>
                        <td>
                          {company.profile.city}
                          {", "}
                          {company.profile.state}
                          {", "}
                          {company.profile.country}
                        </td>
                      </tr>

                      <tr>
                        <td className="font-weight-bold"> Website</td>
                        <td>
                          <a href={company.profile.website} target="_blank">
                            {company.profile.website}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="col-md-6">
                  <Table responsive className="border">
                    <tbody>
                      <tr key="1">
                        <td className="font-weight-bold"> Symbol</td>
                        <td>
                          <Badge variant="light">
                            {company.profile.symbol}
                          </Badge>
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> Public At</td>
                        <td> {company.profile.public_on}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> Listed On</td>
                        <td> {company.profile.exchange_short_name}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">
                          {" "}
                          Shares Outstanding
                        </td>
                        <td>
                          {" "}
                          {formatAmountCompact(
                            company.profile.shares_outstanding
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="font-weight-bold"> Market Cap</td>
                        <td>
                          {" "}
                          {formatAmountCompact(company.profile.market_cap)}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> Market Cap Class</td>
                        <td>
                          {" "}
                          {mapCompanyMarketCapEnumToLabel(
                            company.profile.market_cap_class
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> Growth Phase</td>
                        <td>
                          {" "}
                          {mapCompanyGrowthRateEnumToLabel(
                            company.profile.growth_phase
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> Average Volume</td>
                        <td>
                          {" "}
                          {formatAmountCompact(company.profile.volume_avg)}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> Price Range</td>
                        <td> {company.profile.price_range}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> In S&P 500</td>
                        <td> {company.profile.is_in_sp500 ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> In Nasdaq 100</td>
                        <td>
                          {" "}
                          {company.profile.is_in_nasdaq100 ? "Yes" : "No"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
            <CardFooter>{getFooter(company)} </CardFooter>
          </Card>
          {company.alert && company.alert.length > 0 && (
            <Card>
              <CardHeader title="Alerts"></CardHeader>
              <CardBody>
                <ul>
                  {company.alert.map((f, index) => {
                    return (
                      <li key={index}>
                        <div>
                          <Badge variant="dark">{f.published_at}</Badge>{" "}
                          <Badge
                            variant={
                              f.sentiment_type == 1 ? "primary" : "danger"
                            }
                          >
                            {" "}
                            {f.sentiment_type == 1 ? "Bullish" : "Bearish"}
                          </Badge>{" "}
                          {mapBuyRatingToGradeBadge(f.buy_rating)} {f.contents}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </CardBody>
            </Card>
          )}
          {company.analysis && company.analysis.length > 0 && (
            <Card>
              <CardHeader
                title={
                  <>
                    <img src={company.photo} height={30}></img>{" "}
                    <span>Analysis</span>
                  </>
                }
                toolbar={
                  <div className="card-toolbar">
                    <div className="example-tools">
                      {mapRatingToGradeBadge(0)}
                    </div>
                  </div>
                }
              ></CardHeader>
              <CardBody>
                {company.analysis.map((f, index) => {
                  return (
                    <Card key={index}>
                      <CardHeader
                        title={
                          <div>
                            <span className="navi-icon">
                              <i className="flaticon2-bell-2"></i>
                            </span>{" "}
                            {mapAnalysisSectionEnumToLabel(f.section_type)}
                          </div>
                        }
                        toolbar={
                          <div className="card-toolbar">
                            <div className="example-tools">
                              {mapRatingToGradeBadge(f.rating, RatingScale.P5)}
                            </div>
                          </div>
                        }
                      ></CardHeader>
                      <CardBody>
                        <div>
                          {ReactHtmlParser(reduceHeadingLevels(f.contents, 3))}
                        </div>
                      </CardBody>
                    </Card>
                  );
                })}
              </CardBody>
            </Card>
          )}
          {company.news && company.news.length > 0 && (
            <Card>
              <CardHeader title="News"></CardHeader>
              <CardBody>
                {company.news.map((f, index) => {
                  return (
                    <>
                      <div key={index}>
                        <span className="navi-icon">
                          <i className="flaticon2-bell-2"></i>
                        </span>{" "}
                        <a href={f.link} target="_blank">
                          {f.title}
                        </a>
                      </div>

                      <div>{f.teaser}</div>
                      <br></br>
                    </>
                  );
                })}
              </CardBody>
            </Card>
          )}

          {company.faq && company.faq.length > 0 && (
            <Card>
              <CardHeader title="FAQ"></CardHeader>
              <CardBody>
                <Accordion>
                  <ul>
                    {company.faq.map((f, index) => {
                      return (
                        <li key={index}>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={index}
                          >
                            <div>{f.question}</div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={index}>
                            <div>{f.answer}</div>
                          </Accordion.Collapse>
                        </li>
                      );
                    })}
                  </ul>
                </Accordion>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyView;
