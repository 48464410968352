// @flow
import { toAbsoluteUrl } from "_metronic/_helpers/AssetsHelpers";
import { Notice } from "_metronic/_partials/controls/Notice";
import { useSubheader } from "_metronic/layout/index";

import React, { useEffect } from "react";
import { CardGroup } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { useMetaTags } from "app/utils/SeoFunction";
import { SearchHome } from "app/pages/SearchHome";
import CompanyGrid from "../components/CompanyGrid";
import CompanyList from "app/components/CompanyList";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "_metronic/_partials/controls";
import { CustomersTable } from "app/modules/ECommerce/pages/customers/customers-table/CustomersTable";
import { CustomersFilter } from "app/modules/ECommerce/pages/customers/customers-filter/CustomersFilter";
import { CustomersUIConsumer } from "app/modules/ECommerce/pages/customers/CustomersUIContext";
import { CustomersUIProvider } from "app/modules/ECommerce/pages/customers/CustomersUIContext";
import { CustomersLoadingDialog } from "app/modules/ECommerce/pages/customers/customers-loading-dialog/CustomersLoadingDialog";
import { CompanyScreenCard } from "app/components/screener/CompanyScreenCard";
import { CompanyScreenUIProvider } from "app/components/screener/CompanyScreenUIContext";
/**
 * Represents the Home page
 * @returns
 */
export const HomePage = (): React$MixedElement => {
  const suhbeader = useSubheader();
  useMetaTags("Home");

  useEffect(() => {
    suhbeader.setTitle("Home");
  });

  return (
    <>
      {/* <SearchHome></SearchHome> */}
      <br></br>
      {/* <CustomersUIProvider customersUIEvents={{}}>
        <CustomersLoadingDialog />

        <Card>
          <CardHeader title="Customers list">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {}}
              >
                New Customer
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <CustomersFilter />
            <CustomersTable />
          </CardBody>
        </Card>
        <CompanyList></CompanyList> 
      </CustomersUIProvider> */}
      <CompanyScreenUIProvider companyScreenUIEvents={{}}>
        <CustomersLoadingDialog />

        <CompanyScreenCard></CompanyScreenCard>
      </CompanyScreenUIProvider>
    </>
  );
};

function getHomeContent() {
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <div className={`card card-custom bg-gray-100`}>
            {/* Header */}
            <div className="card-header border-0 bg-warning py-5">
              <h3 className="card-title font-weight-bolder text-white">
                <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Tools/Shovel.svg")}
                  ></SVG>
                </span>
                InvestorBite <br></br> {process.env.REACT_APP_WEBSITE_MOTTO}
              </h3>
            </div>
            {/* Body */}
            <div className="card-body p-0 position-relative overflow-hidden">
              {/* Chart */}
              <div
                id="kt_mixed_widget_1_chart"
                className="card-rounded-bottom bg-success"
                style={{ height: "125px" }}
              ></div>

              {/* Stat */}
              <div className="card-spacer mt-n25">
                <div className="row m-0">
                  <div className="col bg-light-success px-6 py-8 rounded-xl mr-7 mb-7">
                    <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Media/Equalizer.svg"
                        )}
                      ></SVG>
                    </span>
                    <Link
                      className="text-success font-weight-bold font-size-h6"
                      to="/learn/home"
                    >
                      Learn & Grow
                    </Link>
                  </div>
                  <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
                    <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Tools/Shovel.svg")}
                      ></SVG>
                    </span>
                    <Link
                      color="inherit"
                      to="/tool/home"
                      className="text-primary font-weight-bold font-size-h6"
                    >
                      Tools &amp; Calculators
                    </Link>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">
                    <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Add-user.svg"
                        )}
                      ></SVG>
                    </span>
                    <Link
                      color="inherit"
                      to="/problem/home"
                      className="text-danger font-weight-bold font-size-h6"
                    >
                      Problem &amp; Remedies
                    </Link>
                  </div>
                  <div className="col bg-light-warning px-6 py-8 rounded-xl">
                    <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Write.svg"
                        )}
                      ></SVG>
                    </span>

                    <Link
                      color="inherit"
                      to="/ask/home"
                      className="text-warning font-weight-bold font-size-h6"
                    >
                      Ask &amp; Discuss
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <CardGroup>
            <Card>
              <Card.Body>
                <Card.Title>
                  <Link
                    className="text-success font-weight-bold font-size-h6"
                    to="/learn/home"
                  >
                    Learn & Grow
                  </Link>
                </Card.Title>
                <Card.Text>
                  Follow simple investing guides to grow your assets and
                  equities.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  Practical and comprehensive investing guides
                </small>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>
                  {" "}
                  <Link
                    className="text-primary font-weight-bold font-size-h6"
                    to="/tool/home"
                  >
                    Tools & Calculators
                  </Link>
                </Card.Title>
                <Card.Text>
                  Value add tools and calculators to eliminate the guess work,
                  understand and take control of your investment and enjoy its
                  benefits.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  Tools & Calculator for simplifying your investing tasks
                </small>
              </Card.Footer>
            </Card>
          </CardGroup>

          <br />
          <br />
          <CardGroup>
            <Card>
              <Card.Body>
                <Card.Title>
                  <Link
                    className="text-danger font-weight-bold font-size-h6"
                    to="/problem/home"
                  >
                    Problem &amp; Remedies
                  </Link>
                </Card.Title>
                <Card.Text>
                  Get relief from common investing problems with time tested
                  solutions that simply work and maximize your output.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  Simple and effective investment remedies
                </small>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>
                  <Link
                    className="text-warning font-weight-bold font-size-h6"
                    to="/ask/home"
                  >
                    Ask & Discuss
                  </Link>
                </Card.Title>
                <Card.Text>
                  Have a specific investing related question or issue? Simply
                  post your question and get answers from the community right
                  away.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  Get advice from peers and other investors
                </small>
              </Card.Footer>
            </Card>
          </CardGroup>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <Notice>
            <h1>Investment Rule!</h1>
            <p>
              "It's far better to buy a wonderful company at a fair price than a
              fair company at a wonderful price." – Warren Buffet
            </p>
            <p>{/*<Button variant="primary">Learn more</Button>*/}</p>
          </Notice>
        </div>
      </div>
    </>
  );
}
