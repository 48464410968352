import { useQuery } from "react-query";
import { GraphQLClient } from "graphql-request";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

/**
 * Main method used for querying the RM graphql API
 * @param {*} queryName
 * @param {*} query
 * @param {*} variables
 * @returns
 */
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;

// hook
export function useApiQuery(queryName: string, query, variables) {
  return useQuery(queryName, () => {
    const client = new GraphQLClient(GRAPHQL_ENDPOINT);
    client.setHeader("Authorization", `Bearer ${getAPIToken()}`);
    return client.request(query, variables);
  });
}

// api
export async function runApiQuery(query, variables) {
  console.log("runApiQuery");
  const client = new GraphQLClient(GRAPHQL_ENDPOINT);
  client.setHeader("Authorization", `Bearer ${getAPIToken()}`);
  return client.request(query, variables);
}

export function getAPIToken() {
  let token = localStorage.getItem("TOKEN");
  if (token) return token;
  return AUTH_TOKEN;
}

// record page view
export const recordPageView = (url, title = "") => {
  window.dataLayer.push({
    event: "pageview",
    page: {
      url: url,
      title: title,
    },
  });
};

// google analytics trackers
// NOT USED : NOT WORKING
const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("UA-1296266-55");
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export default useGaTracker;
