import { runApiQuery } from "app/utils/APIFunctions";
import { gql } from "graphql-request";
import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { Table, Badge, Image } from "react-bootstrap";
import ReactGA from "react-ga";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls/Card";
import { useMetaTags } from "app/utils/SeoFunction";

const SEARCH_FODMAP_ITEMS = gql`
  query($kw: String!) {
    searchFodmapItemQuery(keywords: $kw) {
      name
      tags
      photo
      allowed_quantity
      fodmap_level
      polyols_level
      fructose_level
      lactose_level
      oligos_level
    }
  }
`;

export default function FodmapTool({ tool, toolbar }) {
  const [searchText, setSearchText] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  useMetaTags(tool.title, tool.descr);

  // return a label corresponding to a level
  const levelToBadge = (level) => {
    let label = "unknown";
    let style = "dark";
    if (level === 0) {
      label = "Low";
      style = "success";
    } else if (level === 1) {
      label = "Medium";
      style = "warning";
    } else if (level === 2) {
      label = "High";
      style = "danger";
    }
    return <Badge variant={style}>{label}</Badge>;
  };
  // handle selection
  const handleChange = (value) => {
    setSelectedItem(value);
    ReactGA.event({
      category: "Fodmap Tool",
      action: "Item Selected",
      label: value.label,
    });
  };

  // handle input change event
  const handleInputChange = (value) => {
    //setSelectedValue(null);
    //console.log("value" + value);
    setSearchText(value);
  };

  // load the options 0- make the api calls
  const loadOptions = async (inputText, callback) => {
    console.log("in : loadOptions");
    const variables = {
      kw: searchText,
    };
    const data = await runApiQuery(SEARCH_FODMAP_ITEMS, variables);
    // map it into format as requried by the control
    console.log(data);
    callback(
      data.searchFodmapItemQuery.map((item) => ({
        label: item.name,
        value: item,
      }))
    );
  };

  return (
    <>
      <Card className="sticky-top-panel">
        <CardHeader title={tool.title} toolbar={toolbar} />
        <CardBody>
          <div>
            <AsyncSelect
              inputId="react-select-single"
              placeholder="Enter food item to search..."
              loadOptions={loadOptions}
              value={selectedItem}
              onChange={handleChange}
              onInputChange={handleInputChange}
            />
          </div>
          {selectedItem && (
            <>
              <br />
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td>
                      <h2>{selectedItem.value.name}</h2>
                    </td>
                  </tr>
                  {selectedItem.value.photo && (
                    <tr>
                      <td>
                        <h2>
                          <Image src={selectedItem.value.photo} thumbnail />{" "}
                        </h2>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      Fodmap Level &nbsp;&nbsp;
                      {levelToBadge(selectedItem.value.fodmap_level)}
                    </td>
                  </tr>
                  {selectedItem.value.allowed_quantity && (
                    <tr>
                      <td>
                        Allowed quantity &nbsp;&nbsp;
                        <Badge variant="info">
                          {selectedItem.value.allowed_quantity}
                        </Badge>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Component</th>
                    <th>Fodmap Level</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Oligosaccharides</td>
                    <td>{levelToBadge(selectedItem.value.oligos_level)}</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Disaccharides</td>
                    <td>{levelToBadge(selectedItem.value.lactose_level)}</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Monosaccharides</td>
                    <td>{levelToBadge(selectedItem.value.fructose_level)}</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Polyols</td>
                    <td>{levelToBadge(selectedItem.value.polyols_level)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
