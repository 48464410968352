// @flow
import { ToolToolbar } from "app/components/ToolToolbar";
import React from "react";
import { Badge } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "_metronic/_partials/controls/Card";
import type { Remedy } from "../utils/Constants";
import {
  Button,
  Overlay,
  Tooltip,
  OverlayTrigger,
  ButtonToolbar,
} from "react-bootstrap";
import clsx from "clsx";

/**
 * Represents a Remedy
 * @returns
 */
type Props = {
  remedy: Remedy,
};

const toolbar = (
  <div className="card-toolbar">
    <div className="example-tools">xx{<ToolToolbar />}</div>
  </div>
);

const getHeaderToolbar = (remedy: Remedy) => {
  return (
    <div className="card-toolbar">
      <div className="example-tools">
        <span
          style={{ cursor: "hand" }}
          onClick={() => {
            console.log("clicked");
          }}
        >
          <i className="flaticon-paper-plane-1 text-success icon-lg"></i>
        </span>
      </div>
    </div>
  );
};

const getFooterToolbar = (remedy: Remedy) => {
  return (
    <div className="card-toolbar">
      <div className="example-tools">
        <span
          style={{ cursor: "hand" }}
          onClick={() => {
            console.log("clicked");
          }}
        >
          Did this remedy work for you ?{" "}
          <i className="flaticon-paper-plane-1 text-success icon-lg"></i>
          &nbsp;
          <i className="flaticon-paper-plane-1 text-success icon-lg"></i>
        </span>
      </div>
    </div>
  );
};
const getHeader = (remedy: Remedy) => {
  return (
    <>
      <i className="flaticon-paper-plane-1 text-success icon-lg"></i>{" "}
      <span>{remedy.title}</span>
      {/* <Badge variant="success">Remedy</Badge> <span>{remedy.title}</span> */}
    </>
  );
};

const getFooter = (remedy: Remedy) => {
  return (
    <>
      {remedy.tags &&
        remedy.tags.split(",").map((tag) => (
          <>
            <Badge variant="light">{tag}</Badge>&nbsp;
          </>
        ))}
    </>
  );
};
const RemedyView = ({ remedy }: Props): React$MixedElement => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Card>
            <CardHeader
              title={getHeader(remedy)}
              toolbar={getFooterToolbar(remedy)}
            />{" "}
            <CardBody>
              <div>{ReactHtmlParser(remedy.contents)}</div>
            </CardBody>
            <CardFooter>{getFooter(remedy)}</CardFooter>
          </Card>
        </div>
      </div>
    </>
  );
};

export default RemedyView;
