// @flow
import type { Company } from "../utils/Constants";
import { ProgressBar, Badge } from "react-bootstrap";
import CompanyView from "../components/CompanyView";
import RemedyView from "../components/RemedyView";
import { useSubheader } from "_metronic/layout/index";

import { recordPageView, runApiQuery } from "app/utils/APIFunctions";
import { useMetaTags } from "app/utils/SeoFunction";
import { gql } from "graphql-request";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls/Card";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  mapBuyRatingEnumToLabel,
  mapBuyRatingToGradeBadge,
} from "app/utils/UtilFunctions";
import CompanyScoreCard from "app/pages/CompanyScoreCard";

const CompanyBasePage = (props): React$MixedElement => {
  const companyURL = props.location.pathname;
  console.log("CompanyPage" + companyURL);

  const [company: Company, setCompany] = useState(null);
  const [companyGuid: string, setCompanyGuid] = useState(
    companyURL.split("/")[2]
  );
  // check if got referred via search
  const query = new URLSearchParams(props.location.search);
  const guidTypeValue = query.get("guid_type");
  console.log("guidType", guidTypeValue);
  const [guidType: string, setGuidType] = useState(guidTypeValue);

  const suhbeader = useSubheader();

  useMetaTags("Stock Investment Reports");

  const FRAGMENT_COMPANY_FIELDS = gql`
    fragment CompanyFields on Company {
      __typename
      guid
      title
      descr
      contents
      tags
      photo
      profile {
        website
        employees
        sector
        industry
        symbol
        website
        employees
        public_on
        founded_on
        sector
        industry
        peers
        exchange
        currency
        exchange_short_name
        phone
        address
        city
        state
        zip
        country
        last_div_amount
        is_in_sp500
        shares_float
        shares_outstanding
        market_cap
        volume_avg
        public_on
        last_refresh_at
        float_free_perc
        beta
        ceo
        price_range
        dividend_yield_class
        is_dividend_aristocrat
        market_cap_class
      }
      data {
        revenue
        cost_of_revenue
      }
      metric {
        quick_ratio
        current_ratio
      }
      news {
        title
        teaser
        link
      }
      faq {
        question
        answer
        weight
      }
      analysis {
        section_type
        contents
        rating
      }
      alert {
        contents
        sentiment_type
        buy_rating
        published_at
      }
      score {
        business_score
        management_score
        financial_score
        growth_score
        profitability_score
        analysis_score
        valuation_score
        external_score
        score
        rank
        buy_rating
        last_refresh_at
      }
      relatedCompanies {
        title
        guid
        seo_guid
      }
    }
  `;

  const GET_COMPANY_QUERY = gql`
    query($guid: ID!) {
      companyQuery(guid: $guid) {
        ...CompanyFields
      }
    }
    ${FRAGMENT_COMPANY_FIELDS}
  `;

  const GET_COMPANY_QUERY_BY_NODE = gql`
    query($guid: ID!) {
      companyQuery(node_guid: $guid) {
        ...CompanyFields
      }
    }
    ${FRAGMENT_COMPANY_FIELDS}
  `;

  useEffect(() => {
    console.log("in useEffect");
    const loadCompany = async () => {
      if (company == null) {
        let data = null;
        const variables = {
          guid: companyGuid,
        };
        if (guidType == null) {
          data = await runApiQuery(GET_COMPANY_QUERY, variables);
        } else {
          data = await runApiQuery(GET_COMPANY_QUERY_BY_NODE, variables);
        }
        //    console.log(data);
        if (data.companyQuery && data.companyQuery[0]) {
          setCompany(data.companyQuery[0]);
          //console.log(data.companyQuery[0]);
        }
      }
    };
    loadCompany();
    if (company && suhbeader.title !== company.title) {
      suhbeader.setTitle(company.title);

      let breadcrumbs;

      breadcrumbs = [
        {
          title: "Companies",
          pathname: "/company/home",
        },
      ];
      suhbeader.setBreadcrumbs(breadcrumbs);
    }
    recordPageView(companyURL);
  }, [companyGuid, companyURL, company, suhbeader, GET_COMPANY_QUERY, props]);

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          {company && <CompanyView company={company}></CompanyView>}
        </div>
        <div className="col-md-3 sticky-top-panel">
          {company?.score && (
            <CompanyScoreCard score={company.score}></CompanyScoreCard>
          )}
          <Card>
            <CardHeader title={"Peers"} toolbar={() => {}}>
              <ProgressBar
                variant={"warning"}
                animated
                now={100}
                style={{ height: "5px", width: "100%" }}
              />
            </CardHeader>
            <CardBody>
              <ul>
                {company?.relatedCompanies?.map((company, index) => {
                  company.index = index;

                  return (
                    <li key={company.guid}>
                      {" "}
                      <Link
                        to={`/company/${company.guid}/${company.seo_guid}`}
                        onClick={() => {
                          setCompanyGuid(company.guid);
                          setCompany(null);
                          setGuidType(null);
                        }}
                        key={company.guid}
                      >
                        {company.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export const CompanyPage = withRouter(CompanyBasePage);
