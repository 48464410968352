import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Link } from "react-router-dom";
/**
 * Footer Component
 * @returns
 */
export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">
            {today.toString()}
          </span>{" "}
          &copy; InvestorBite -
          <small>{process.env.REACT_APP_WEBSITE_MOTTO}</small>
          <br></br>
          <small>
            InvestorBite does not provide financial advice and does not issue
            recommendations or offers to buy stock or sell any security.
            <br></br>
            Information is provided 'as-is' and solely for informational
            purposes and is not advice. <br></br>InvestorBite does not bear any
            responsibility for any losses or damage that may occur as a result
            of reliance on this data or analysis.
          </small>
        </div>
        <div className="nav nav-dark order-1 order-md-2">
          <Link className="nav-link px-3" to="/about">
            About
          </Link>
          <Link className="nav-link px-3" to="/privacy">
            Privacy
          </Link>
          <Link className="nav-link px-3" to="/terms">
            Terms
          </Link>
        </div>
      </div>
    </div>
  );
}
