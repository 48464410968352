/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function SearchDropdownResult({ data }) {
  console.log("data", data);
  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return (
      <div className="quick-search-result">
        {/* begin: Section */}
        <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
          No Results Found.
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ maxHeight: "325px", overflow: "hidden" }}
      className="quick-search-wrapper scroll ps ps--active-y"
    >
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        className="scroll"
        style={{ maxHeight: "325px", position: "relative" }}
      >
        <div className="quick-search-result">
          {/* begin: Section */}
          <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
            Results
          </div>
          {data.map((result) => (
            <div key={result.guid} className="mb-10">
              {console.log(result)}
              <div className="d-flex align-items-center flex-grow-1 mb-2">
                <div className="symbol symbol-45 bg-transparent flex-shrink-0">
                  <SVG src={result.iconImage}></SVG>
                </div>
                <div className="d-flex flex-column ml-3 mt-2 mb-2">
                  <Link
                    className="font-weight-bold text-dark text-hover-primary"
                    to={result.url}
                  >
                    {result.text}
                  </Link>
                  <span className="font-size-sm font-weight-bold text-muted">
                    {result.type}
                  </span>
                </div>
              </div>
            </div>
          ))}
          {/* end: Section */}
        </div>
      </PerfectScrollbar>
    </div>
  );
}
