// @flow
import React, { useState, useRef, useEffect } from "react";
import { Badge } from "react-bootstrap";
import { Table } from "react-bootstrap";
import {
  SexEnum,
  ActivityTypeEnum,
  CalorieCounterMethodEnum,
  WeightChangePlanRateTypeEnum,
} from "app/utils/ValueTypes";
import type {
  SexType,
  ActivityType,
  CalorieCounterMethodType,
} from "app/utils/ValueTypes";
import { Form, Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls/Card";
import { ProgressBar } from "react-bootstrap";
import { mapChangeInWeeklyWeightPlanToRateType } from "app/utils/LibFunctions";
import { titleCase } from "app/utils/UtilFunctions";
import {
  Unit,
  UnitDefinition,
  fromKgToLb,
  fromLbToKg,
  fromFeetInchesToCm,
  ConversionFormat,
  formatValue,
} from "app/utils/Measurement";

import type {
  MassType,
  DurationType,
  DistanceType,
} from "app/utils/Measurement";
import type { WeightChangePlanRateType } from "../../utils/ValueTypes";
import type { Tool } from "app/utils/Constants";

import { useMetaTags } from "app/utils/SeoFunction";

type Results = {
  changeInWeight: number,
  changeInWeightLabel: string,
  changeInWeightPerWeekLabel: string,
  changeInWeightPlanRate: WeightChangePlanRateType,
  changeInCalories: number,
  caloriesForCurrent: number,
  caloriesToReachTarget: number,
  caloriesAfterReachingTarget: number,
};

type Input = {
  sex: SexType,
  height: {
    unit: DistanceType,
    value1?: number,
    value2?: number,
  },
  weight: {
    unit: MassType,
    value: number,
  },
  age: number,
  activity: ActivityType,
  targetWeight: {
    unit: MassType,
    value: number,
  },
  goalTimeframe: {
    unit: DurationType,
    value?: number,
  },
};

type Errors = {
  sex?: string,
  height?: string,
  weight?: string,
  age?: string,
  activity?: string,
  targetWeight?: string,
  goalTimeframe?: string,
};

export const WeightGainToolMode = {
  GAIN: 1,
  LOSS: 2,
};

// returns the default state based on the mode of the tool
const getDefaultInputState = (
  mode: $Values<typeof WeightGainToolMode>
): Input => {
  let defaultState: Input = {
    sex: SexEnum.male,
    height: {
      unit: Unit.Distance.FEET,
      value1: 5,
      value2: 10,
    },
    weight: {
      unit: Unit.Mass.POUND,
      value: 150,
    },
    age: 30,
    activity: ActivityTypeEnum.Sedentry.value,
    targetWeight: {
      unit: Unit.Mass.POUND,
      value: mode === WeightGainToolMode.GAIN ? 155 : 145,
    },
    goalTimeframe: {
      unit: Unit.Duration.DAY,
      value: 30,
    },
  };

  return defaultState;
};

export type Props = {
  tool: Tool,
  toolbar: any,
  mode: $Values<typeof WeightGainToolMode>,
};
// Tool to calculate weight gain
export default function WeightGainTool({ tool, toolbar, mode }: Props) {
  const [validated, setValidated] = useState(false);
  const [results: Results, setResults] = useState(null);
  const [errors: Errors, setErrors] = useState({});
  const [input: Input, setInput] = useState(getDefaultInputState(mode));
  const formRef = useRef();
  const [progress: number, setProgress] = useState(20);
  useMetaTags(tool.title, tool.descr);
  // apply changes after when the view is rendered
  useEffect(() => {
    console.log("in : useEffect");
    const newErrors = validateFormInput();
    let totalErrors = Object.keys(newErrors).length;
    console.log("new errors " + totalErrors);
    let newProgress = 100 - totalErrors * 15;
    setProgress(newProgress);
    if (totalErrors > 0) {
      setResults(null);
      setErrors(newErrors);
    } else {
      computeResultsOnSuccess();
    }
  }, [input]);

  // validate all the form
  const validateFormInput = () => {
    console.log("in : validateFormInput");
    const { height, weight, age, targetWeight, goalTimeframe }: Input = input;
    const newErrors = {};
    // height errors

    if (height.unit === Unit.Distance.FEET) {
      if (!height.value1) newErrors.height = "Enter your height.";
      else if (height.value1 <= 0)
        newErrors.height = "Value in feet must be > 0.";
      else if (!height.value2) newErrors.heightSec = "Enter value in inches";
      else if (height.value2 < 0)
        newErrors.heightSec = "Value in inches must be >= 0.";
      else if (height.value2 >= 12)
        newErrors.heightSec = "Value in inches must be <=11.";
    } else {
      if (!height.value1) newErrors.height = "Enter your height.";
      else if (height.value1 <= 0) newErrors.height = "Height must be > 0.";
    }

    if (!weight.value) newErrors.weight = "Enter your weight.";
    else if (parseInt(weight.value) <= 0)
      newErrors.weight = "Weight must be > 0.";

    if (age == null) newErrors.age = "Enter your age.";
    else if (parseInt(age) <= 13) newErrors.age = "Age must be > 13.";
    else if (parseInt(age) > 120) newErrors.age = "Age cannot be >= 120.";

    if (!targetWeight.value)
      newErrors.targetWeight = "Enter your target weight.";
    else if (parseInt(targetWeight.value) <= 0)
      newErrors.targetWeight = "Weight must be > 0.";
    else if (weight.value && targetWeight.value) {
      // both weights are present
      // noramlize and compare
      console.log("Both target and current have value");
      if (weight.unit === targetWeight.unit) {
        console.log("Both target and current have same units");
        if (mode === WeightGainToolMode.GAIN) {
          if (parseInt(targetWeight.value) < parseInt(weight.value)) {
            newErrors.targetWeight =
              "Target weight must be greater than your current weight.";
          }
        } else {
          if (parseInt(targetWeight.value) > parseInt(weight.value)) {
            newErrors.targetWeight =
              "Target weight must be less than your current weight.";
          }
        }
      } else {
        // convert one to another
        // handle weight
        console.log("Both target and current have diff units");
        let currentWeightInTargetWeightUnits = 0;
        if (targetWeight.unit === Unit.Mass.KILOGRAM) {
          currentWeightInTargetWeightUnits = fromLbToKg(weight.value);
        } else if (targetWeight.unit === Unit.Mass.POUND) {
          currentWeightInTargetWeightUnits = fromKgToLb(weight.value);
        } else {
          console.log(
            "Something went wrong, weight units is know known" +
              targetWeight.unit
          );
        }
        console.log(
          "target weight",
          targetWeight.value,
          "current",
          currentWeightInTargetWeightUnits
        );
        if (mode === WeightGainToolMode.GAIN) {
          if (targetWeight.value < currentWeightInTargetWeightUnits) {
            newErrors.targetWeight =
              "Target weight must be greater than your current weight.";
          }
        } else {
          if (targetWeight.value > currentWeightInTargetWeightUnits) {
            newErrors.targetWeight =
              "Target weight must be less than your current weight.";
          }
        }
      }
    }

    if (!goalTimeframe.value) newErrors.goalTimeframe = "Enter your timeframe.";
    else if (parseInt(goalTimeframe.value) <= 0)
      newErrors.goalTimeframe = "Duration must be > 0.";

    console.log(newErrors);
    return newErrors;
  };

  const handleReset = (event) => {
    console.log("Reset the form");
    // $FlowFixMe
    formRef.current.reset();

    setInput(getDefaultInputState(mode));
    setErrors({});
    setResults(null);
    setProgress(100);
    setValidated(false);

    // example of filter elements etc.
    // const formInputs = [...fromRef.current.elements].filter(
    //   element => element.type === "text"
    // );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    const newErrors = validateFormInput();
    // Conditional logic:
    if (form.checkValidity() === false || Object.keys(newErrors).length > 0) {
      console.log("Form is not valid");
      // We got errors!
      setErrors(newErrors);
      setResults(null);
    } else {
      computeResultsOnSuccess();
    }
  };

  const toolbarX = () => (
    <>
      <span
        onClick={(e) => handleReset(e)}
        style={{ marginTop: "15px", cursor: "pointer" }}
      >
        <i className="fa fa-undo icon"></i>
      </span>
    </>
  );

  const computeResultsOnSuccess = () => {
    console.log("in : computeResultsOnSuccess");
    // No errors!
    // Put any logic here for the form submission!
    setValidated(true);
    setProgress(100);
    setErrors({});
    // compute Result
    computeResults(CalorieCounterMethodEnum.MIFFLIN_ST_JEOR);
  };

  // compute results
  const computeResults = (method: CalorieCounterMethodType) => {
    const {
      sex,
      height,
      weight,
      age,
      targetWeight,
      goalTimeframe,
      activity,
    } = input;

    // convert weight to kg,
    // convert height to cm
    //  age to years , no conversion needed
    //  body fat %, not use yet
    let weightKg: number = 0;
    let heightInCm: number = 0;
    let targetWeightKg: number = 0;

    // handle weight
    if (weight.unit === Unit.Mass.POUND) {
      weightKg = fromLbToKg(weight.value);
      console.log("Weight", weightKg);
    } else if (weight.unit === Unit.Mass.KILOGRAM) {
      weightKg = parseInt(weight.value);
    } else {
      console.log(
        "Something went wrong, weight units is not known" + weight.unit
      );
    }

    // handle height
    if (height.unit === Unit.Distance.FEET) {
      heightInCm = fromFeetInchesToCm(
        parseInt(height.value1),
        parseInt(height.value2)
      );
    } else if (height.unit === Unit.Distance.CENTIMETER) {
      heightInCm = parseInt(height.value1);
    } else {
      console.log(
        "Something went wrong, height units is not known" + height.unit
      );
    }

    // handle weight
    if (targetWeight.unit === Unit.Mass.POUND) {
      targetWeightKg = fromLbToKg(targetWeight.value);
      console.log("Target Weight in kg after", targetWeightKg);
    } else if (targetWeight.unit === Unit.Mass.KILOGRAM) {
      targetWeightKg = parseInt(targetWeight.value);
    } else {
      console.log(
        "Something went wrong, weight units is know known" + targetWeight.unit
      );
    }

    let calForCurrent = 10 * weightKg + 6.25 * heightInCm - 5 * parseInt(age);
    let calAfterReachingTarget =
      10 * targetWeightKg + 6.25 * heightInCm - 5 * parseInt(age);

    //1.2-1.95
    let factor = 1;
    if (activity === ActivityTypeEnum.Sedentry.value) {
      factor = 1.2;
    } else if (activity === ActivityTypeEnum.Light.value) {
      factor = 1.4;
    } else if (activity === ActivityTypeEnum.Moderate.value) {
      factor = 1.6;
    } else if (activity === ActivityTypeEnum.Heavy.value) {
      factor = 1.8;
    } else if (activity === ActivityTypeEnum.Hard.value) {
      factor = 1.95;
    }

    console.log("Factor " + factor);
    if (sex === SexEnum.male) {
      calForCurrent += 5;
      calAfterReachingTarget += 5;
    } else {
      calForCurrent -= 161;
      calAfterReachingTarget -= 161;
    }
    calForCurrent *= factor;
    calAfterReachingTarget *= factor;
    calForCurrent = Math.floor(calForCurrent);
    calAfterReachingTarget = Math.floor(calAfterReachingTarget);

    let calToReachTarget = 0;
    // compute week

    let timeframeInWeeks: number = 0;
    console.log("goal timeframe " + goalTimeframe.unit);
    if (goalTimeframe.unit === Unit.Duration.DAY) {
      timeframeInWeeks = parseInt(goalTimeframe.value) / 7;
    } else if (goalTimeframe.unit === Unit.Duration.MONTH) {
      timeframeInWeeks = parseInt(goalTimeframe.value) * 4;
    } else if (goalTimeframe.unit === Unit.Duration.YEAR) {
      timeframeInWeeks = parseInt(goalTimeframe.value) * 52;
    } else if (goalTimeframe.unit === Unit.Duration.WEEK) {
      timeframeInWeeks = parseInt(goalTimeframe.value);
    } else {
      console.log(
        "Something went wrong, goal time frame units is not known :" +
          goalTimeframe.unit
      );
    }

    let weightChangeInKg = targetWeightKg - weightKg;
    let weightChangelb = fromKgToLb(weightChangeInKg, ConversionFormat.Ceil);
    let changeLbPerWeek = weightChangelb / timeframeInWeeks;
    // Fact : 500 calories per week is required for 1b of weight change
    let calorieChange = Math.ceil(changeLbPerWeek * 500);

    let changeInWeightLabel = null;
    let changeInWeightPerWeekLabel = null;
    let changeInWeight = 0;
    let changeInWeightPerWeek = 0;

    if (targetWeight.unit === Unit.Mass.POUND) {
      changeInWeight = weightChangelb;
      changeInWeightLabel = Math.abs(changeInWeight) + " " + targetWeight.unit;
      changeInWeightPerWeek = formatValue(
        changeLbPerWeek,
        ConversionFormat.Round2Digit
      );
      changeInWeightPerWeekLabel =
        Math.abs(changeInWeightPerWeek) + " " + targetWeight.unit + "/week";
    } else {
      changeInWeight = formatValue(
        weightChangeInKg,
        ConversionFormat.Round2Digit
      );

      changeInWeightLabel = changeInWeight + " " + targetWeight.unit;

      changeInWeightPerWeek = fromLbToKg(
        changeLbPerWeek,
        ConversionFormat.Round2Digit
      );
      changeInWeightPerWeekLabel =
        changeInWeightPerWeek + " " + targetWeight.unit + "/week";
    }
    console.log(
      `weight change ${weightChangelb} change per week ${changeLbPerWeek} calorie change ${calorieChange}`
    );

    let changeInCaloriesDaily = calorieChange;
    calToReachTarget = calForCurrent + calorieChange;
    console.log("target  cal " + calToReachTarget);
    setResults(
      ({
        changeInWeight: changeInWeight,
        changeInWeightLabel: changeInWeightLabel,
        changeInWeightPerWeekLabel: changeInWeightPerWeekLabel,
        changeInWeightPlanRate: mapChangeInWeeklyWeightPlanToRateType(
          Math.abs(changeInWeightPerWeek),
          targetWeight.unit
        ),
        changeInCalories: changeInCaloriesDaily,
        caloriesForCurrent: calForCurrent,
        caloriesToReachTarget: calToReachTarget,
        caloriesAfterReachingTarget: calAfterReachingTarget,
      }: Results)
    );
  };

  // set a given field
  const setField = (field, value: any) => {
    console.log(`set ${field} with value `);
    switch (field) {
      case "sex":
        setInput({ ...input, sex: value });
        break;
      case "height":
        setInput({ ...input, height: value });
        break;
      case "heightSec":
        setInput({ ...input, height: value });
        break;
      case "weight":
        setInput({ ...input, weight: value });
        break;
      case "age":
        setInput({ ...input, age: value });
        break;
      case "activity":
        setInput({ ...input, activity: value });
        break;
      case "targetWeight":
        setInput({ ...input, targetWeight: value });
        break;
      case "goalTimeframe":
        console.log("value" + value.unit + "-" + value.value);
        setInput({ ...input, goalTimeframe: value });
        break;
      default:
        console.log(`Unknow field ${field} in setField`);
        return;
    }

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) {
      console.log(`Remove ${field} from errors list`);
      delete errors[field];
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <Card className="sticky-top-panel">
            <CardHeader
              title={"Personal Details / Target Weight / Plan"}
              toolbar={toolbarX()}
            >
              <ProgressBar
                variant={"success"}
                animated
                now={progress}
                style={{ height: "5px", width: "100%" }}
              />
            </CardHeader>
            <CardBody>
              <Form
                noValidate
                validated={validated}
                // $FlowFixMe
                ref={formRef}
                onSubmit={(e) => handleSubmit(e)}
              >
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Sex
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      as="select"
                      required
                      onChange={(e) => setField("sex", e.target.value)}
                    >
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="height">
                  <Form.Label column sm={4}>
                    Height
                  </Form.Label>
                  {input.height.unit === Unit.Distance.FEET && (
                    <>
                      <Col sm={2}>
                        <Form.Control
                          type="number"
                          placeholder="ft"
                          required
                          value={input.height?.value1}
                          onChange={(e) =>
                            setField("height", {
                              ...input.height,
                              value1: e.target.value,
                            })
                          }
                          isInvalid={errors.height ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.height}
                        </Form.Control.Feedback>
                      </Col>

                      <Col sm={2}>
                        <Form.Control
                          type="number"
                          placeholder="in"
                          required
                          value={input.height?.value2}
                          onChange={(e) =>
                            setField("heightSec", {
                              ...input.height,
                              value2: e.target.value,
                            })
                          }
                          isInvalid={errors.heightSec ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.heightSec}
                        </Form.Control.Feedback>
                      </Col>
                    </>
                  )}
                  {input.height.unit === Unit.Distance.CENTIMETER && (
                    <>
                      <Col sm={4}>
                        <Form.Control
                          type="number"
                          placeholder="cm"
                          required
                          value={input.height?.value1}
                          onChange={(e) =>
                            setField("height", {
                              ...input.height,
                              value1: e.target.value,
                            })
                          }
                          isInvalid={errors.height ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.height}
                        </Form.Control.Feedback>
                      </Col>
                    </>
                  )}
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      required
                      onChange={(e) =>
                        setField("height", {
                          ...input.height,
                          unit: e.target.value,
                        })
                      }
                    >
                      <option value={Unit.Distance.FEET}>
                        {Unit.Distance.FEET} {"/ inches"}
                      </option>
                      <option value={Unit.Distance.CENTIMETER}>
                        {Unit.Distance.CENTIMETER}
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="weight">
                  <Form.Label column sm={4}>
                    Weight
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="number"
                      placeholder=""
                      required
                      value={input.weight?.value}
                      onChange={(e) =>
                        setField("weight", {
                          ...input.weight,
                          value: e.target.value,
                        })
                      }
                      isInvalid={errors.weight ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.weight}
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      required
                      onChange={(e) =>
                        setField("weight", {
                          ...input.weight,
                          unit: e.target.value,
                        })
                      }
                    >
                      <option value={Unit.Mass.POUND}>
                        {UnitDefinition.Mass.pound.abbr}
                      </option>
                      <option value={Unit.Mass.KILOGRAM}>
                        {UnitDefinition.Mass.kilogram.abbr}
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="age">
                  <Form.Label column sm={4}>
                    Age
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="number"
                      placeholder=""
                      required
                      value={input.age}
                      onChange={(e) => setField("age", e.target.value)}
                      isInvalid={errors.age ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.age}
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={4}>
                    <Form.Control as="select" required>
                      <option value={Unit.Duration.YEAR}>
                        {UnitDefinition.Duration.year.plural.toLowerCase()}
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="activity">
                  <Form.Label column sm={4}>
                    Activity
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      as="select"
                      required
                      onChange={(e) => setField("activity", e.target.value)}
                    >
                      <option value={ActivityTypeEnum.Sedentry.value}>
                        No Exercise
                      </option>
                      <option value={ActivityTypeEnum.Light.value}>
                        Light Exercise 1-3 days/week
                      </option>
                      <option value={ActivityTypeEnum.Moderate.value}>
                        Moderate Exercise 3-5 days/week
                      </option>
                      <option value={ActivityTypeEnum.Heavy.value}>
                        Heavy Exercise 6-7 days/week
                      </option>
                      <option value={ActivityTypeEnum.Hard.value}>
                        Hard Exercise Daily every day
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="targetWeight">
                  <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        The weight you want to achieve.
                      </Tooltip>
                    }
                  >
                    <Form.Label column sm={4}>
                      Target Weight{" "}
                      {/* <i className="fa fa-info-circle icon-sm text-muted"></i> */}
                    </Form.Label>
                  </OverlayTrigger>
                  <Col sm={4}>
                    <Form.Control
                      type="number"
                      placeholder=""
                      required
                      value={input.targetWeight?.value}
                      onChange={(e) =>
                        setField("targetWeight", {
                          ...input.targetWeight,
                          value: e.target.value,
                        })
                      }
                      isInvalid={errors.targetWeight ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.targetWeight}
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      required
                      onChange={(e) =>
                        setField("targetWeight", {
                          ...input.targetWeight,
                          unit: e.target.value,
                        })
                      }
                    >
                      <option value={Unit.Mass.POUND}>
                        {UnitDefinition.Mass.pound.abbr}
                      </option>
                      <option value={Unit.Mass.KILOGRAM}>
                        {UnitDefinition.Mass.kilogram.abbr}
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="goalTimeframe">
                  <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        The timeframe in which you want to achieve your goal.
                      </Tooltip>
                    }
                  >
                    <Form.Label column sm={4}>
                      Achieve in{" "}
                      <i className="fa fa-info-circle icon-sm text-muted"></i>
                    </Form.Label>
                  </OverlayTrigger>
                  <Col sm={4}>
                    <Form.Control
                      type="number"
                      placeholder=""
                      required
                      value={input.goalTimeframe?.value}
                      onChange={(e) =>
                        setField("goalTimeframe", {
                          ...input.goalTimeframe,
                          value: e.target.value,
                        })
                      }
                      isInvalid={errors.goalTimeframe ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.goalTimeframe}
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      required
                      onChange={(e) =>
                        setField("goalTimeframe", {
                          ...input.goalTimeframe,
                          unit: e.target.value,
                        })
                      }
                    >
                      <option value={Unit.Duration.DAY}>
                        {UnitDefinition.Duration.day.plural.toLowerCase()}
                      </option>
                      <option value={Unit.Duration.WEEK}>
                        {UnitDefinition.Duration.week.plural.toLowerCase()}
                      </option>
                      <option value={Unit.Duration.MONTH}>
                        {UnitDefinition.Duration.month.plural.toLowerCase()}
                      </option>
                      <option value={Unit.Duration.YEAR}>
                        {UnitDefinition.Duration.year.plural.toLowerCase()}
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Form>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-6">
          {results && <OutputPanel results={results} />}
        </div>
      </div>
    </>
  );
}

// output panel
const OutputPanel = ({ results }) => {
  return (
    <Card className="sticky-top-panel">
      <CardHeader title={"Results"}></CardHeader>
      <CardBody>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th colSpan={2}>Daily Calories Required</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Weight to {results.changeInWeight > 0 ? "gain" : "lose"}</td>
              <td>
                <Badge variant="info">{results.changeInWeightLabel}</Badge>{" "}
              </td>
            </tr>
            <tr>
              <td>
                Weight {results.changeInWeight > 0 ? "gain" : "lose"} rate
              </td>
              <td>
                <Badge variant="info">
                  {results.changeInWeightPerWeekLabel}
                </Badge>{" "}
              </td>
            </tr>
            <tr>
              <td>
                {results.changeInWeight > 0 ? "Increase" : "Decrease"} in
                calories
              </td>
              <td>
                <Badge variant="warning">
                  {Math.abs(results.changeInCalories)} calories/day
                </Badge>{" "}
              </td>
            </tr>

            <tr>
              <td>
                Weight {results.changeInWeight > 0 ? "Increase" : "Decrease"}{" "}
                plan
              </td>
              <td>
                {results.changeInWeightPlanRate && (
                  <Badge
                    variant={
                      WeightChangePlanRateTypeEnum[
                        results.changeInWeightPlanRate
                      ].badge
                    }
                  >
                    {titleCase(results.changeInWeightPlanRate)}
                  </Badge>
                )}
              </td>
            </tr>

            <tr>
              <td>To maintain current weight</td>
              <td>
                <Badge variant="success">
                  {results.caloriesForCurrent} calories/day
                </Badge>{" "}
              </td>
            </tr>
            <tr>
              <td>To reach target weight</td>
              <td>
                <Badge variant="danger">
                  {results.caloriesToReachTarget} calories/day
                </Badge>{" "}
              </td>
            </tr>
            <tr>
              <td>To mantain after reaching target</td>
              <td>
                <Badge variant="primary">
                  {results.caloriesAfterReachingTarget} calories/day
                </Badge>{" "}
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
