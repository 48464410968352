import { forEach } from "lodash";
import * as requestFromServer from "./CompanyScreenCrud";
import { companiesSlice, callTypes } from "./CompanyScreenSlice";

const { actions } = companiesSlice;

export const fetchCompanies = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCompanies(queryParams)
    .then((response) => {
      console.log(response);
      //const { totalCount, entities } = response.data;

      const companies = response.companyQuery;
      const totalCount = companies.length;
      console.log("Total : ", totalCount);
      console.log("entities : ", companies);

      // map it
      const entities = [];
      companies.forEach((company) => {
        const companyData = {
          guid: company.guid,
          seoGuid: company.seo_guid,
          title: company.title,
          symbol: company.profile.symbol,
          industry: company.profile.industry,
          sector: company.profile.sector,
          buyRating: company.score?.buy_rating,
          score: company.score?.score,
          rank: company.score?.rank ?? "--",
          marketCapClass: company.profile.market_cap_class,
          photo: company.photo,
        };
        entities.push(companyData);
      });
      console.log("Final Total : ", entities.length);
      dispatch(actions.companiesFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find companies";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const findCompany = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.companiesFetched({ customerForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCompanyById(id)
    .then((response) => {
      const customer = response.data;
      dispatch(actions.customerFetched({ customerForEdit: customer }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCompany = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCompany(id)
    .then((response) => {
      dispatch(actions.customerDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCompany = (customerForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCompany(customerForCreation)
    .then((response) => {
      const { customer } = response.data;
      dispatch(actions.customerCreated({ customer }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCompany = (customer) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCompany(customer)
    .then(() => {
      dispatch(actions.customerUpdated({ customer }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCompanysStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCompanies(ids, status)
    .then(() => {
      dispatch(actions.customersStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update customers status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCompanies = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCompany(ids)
    .then(() => {
      dispatch(actions.customersDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete customers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
