import { ProgressBar, Badge } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "_metronic/_partials/controls/Card";
import { withRouter } from "react-router-dom";
import {
  getDatePart,
  mapBuyRatingEnumToLabel,
  mapBuyRatingToGradeBadge,
  mapRatingToGrade,
  mapRatingToGradeBadge,
  timesAgo,
} from "app/utils/UtilFunctions";

import { CompanyScore, RatingScale } from "app/utils/Constants";

type Props = {
  score: CompanyScore,
};

// Define a style object for the rows
const rowStyle = {
  lineHeight: 2.1,
};

const colStyle = {
  paddingLeft: "10",
};

const CompanyScoreCard = ({ score }: Props): React$MixedElement => {
  return (
    <Card className="sticky-top-panel">
      <CardHeader title={"Grades"} toolbar={() => {}}>
        <ProgressBar
          variant={"info"}
          animated
          now={100}
          style={{ height: "5px", width: "100%" }}
        />
      </CardHeader>
      <CardBody>
        <table>
          <tr style={rowStyle}>
            <td>Business</td>
            <td style={colStyle}>
              {mapRatingToGradeBadge(score.business_score, RatingScale.P100)}
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>Management</td>
            <td style={colStyle}>
              {mapRatingToGradeBadge(score.management_score, RatingScale.P100)}
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>Financials</td>
            <td style={colStyle}>
              {mapRatingToGradeBadge(score.financial_score, RatingScale.P100)}
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>Valuation</td>
            <td style={colStyle}>
              {mapRatingToGradeBadge(score.valuation_score, RatingScale.P100)}
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>Growth</td>
            <td style={colStyle}>
              {mapRatingToGradeBadge(score.growth_score, RatingScale.P100)}
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>Profitability</td>
            <td style={colStyle}>
              {mapRatingToGradeBadge(
                score.profitability_score,
                RatingScale.P100
              )}
            </td>
          </tr>

          <tr style={rowStyle}>
            <td>Analysis Rating</td>
            <td style={colStyle}>
              {mapRatingToGradeBadge(score.analysis_score, RatingScale.P100)}
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>External Factors</td>
            <td style={colStyle}>
              {mapRatingToGradeBadge(score.external_score, RatingScale.P100)}
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>Overall Grade</td>
            <td style={colStyle}>
              {mapRatingToGradeBadge(score.score, RatingScale.P100)} {""}
              {"/"}
              <Badge variant="light">{score.score}</Badge>
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>Buy Rating</td>
            <td style={colStyle}>
              {mapBuyRatingToGradeBadge(score.buy_rating)}
            </td>
          </tr>
        </table>
      </CardBody>
      <CardFooter>
        <small>
          Updated: {getDatePart(score.last_refresh_at)} (
          {timesAgo(score.last_refresh_at)})
        </small>
      </CardFooter>
    </Card>
  );
};

export default CompanyScoreCard;
