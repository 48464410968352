// @flow

import { Unit } from "app/utils/Measurement";
import type { MassType } from "app/utils/Measurement";
import type { SexType, WeightChangePlanRateType } from "./ValueTypes";
import { ThemeColorsEnum } from "./ValueTypes";

import {
  WeightChangePlanRateTypeEnum,
  HealthyWeightComputeAlgoEnum,
  BMIRangeEnum,
  ActivityTypeEnum,
  SexEnum,
} from "./ValueTypes";

import type {
  ActivityType,
  BMIRangeType,
  HealthyWeightComputeAlgoType,
} from "./ValueTypes";
import { ConversionFormat, formatValue } from "./Measurement";

/**
 * Converts the change in weight to
 * @param {the weight change quantity} changeInWeight
 * @param {The unit of weight lb/kg} weightUnit
 * @returns
 */
export const mapChangeInWeeklyWeightPlanToRateType = (
  changeInWeight: number,
  weightUnit: MassType
): WeightChangePlanRateType => {
  let rateTypeEumValue: any = null;
  console.log(
    "in mapChangeInWeeklyWeightPlanToRateType",
    changeInWeight,
    weightUnit
  );
  if (weightUnit === Unit.Mass.POUND) {
    let levels = Object.keys(WeightChangePlanRateTypeEnum);
    rateTypeEumValue = levels.find(
      (level: WeightChangePlanRateType) =>
        changeInWeight >=
          WeightChangePlanRateTypeEnum[level].weightRange.lb.from &&
        changeInWeight <= WeightChangePlanRateTypeEnum[level].weightRange.lb.to
    );
  } else if (weightUnit === Unit.Mass.KILOGRAM) {
    let levels = Object.keys(WeightChangePlanRateTypeEnum);
    rateTypeEumValue = levels.find(
      (level) =>
        changeInWeight >=
          WeightChangePlanRateTypeEnum[level].weightRange.kg.from &&
        changeInWeight <= WeightChangePlanRateTypeEnum[level].weightRange.kg.to
    );
  }
  return rateTypeEumValue;
};

// compute healthy weight based on algo type
export const computeHealthyWeight = (
  algo: HealthyWeightComputeAlgoType,
  sex: SexType,
  heightInInches: number
): number => {
  let algoDef = HealthyWeightComputeAlgoEnum[algo]["formula"][sex];
  let over5feetInches: number =
    heightInInches - 60 > 0 ? heightInInches - 60 : 0;
  return algoDef.minWeightKg + over5feetInches * algoDef.heightFactorInch;
};

// compute healthy weight based on algo type
export const computeHealthyWeightRangeByVariousAlgos = (
  sex: SexType,
  heightInInches: number
): Array<number> => {
  let minWeightInKg = Number.POSITIVE_INFINITY,
    maxWeightInKg = Number.NEGATIVE_INFINITY;

  Object.keys(HealthyWeightComputeAlgoEnum).forEach((algo) => {
    let algoDef = HealthyWeightComputeAlgoEnum[algo]["formula"][sex];
    let over5feetInches: number =
      heightInInches - 60 > 0 ? heightInInches - 60 : 0;
    let weightInKg =
      algoDef.minWeightKg + over5feetInches * algoDef.heightFactorInch;
    if (weightInKg < minWeightInKg) minWeightInKg = weightInKg;
    if (weightInKg > maxWeightInKg) maxWeightInKg = weightInKg;
  });

  return [minWeightInKg, maxWeightInKg];
};

// compute BMI Index
export const computeBMI = (
  heightInMeter: number,
  weightInKg: number
): number => {
  return formatValue(
    weightInKg / (heightInMeter * heightInMeter),
    ConversionFormat.Round1Digit
  );
};

// compute BMI Index
export const mapBMIValueToBMIRangeType = (bmi: number): BMIRangeType | null => {
  console.log("bmi", bmi);
  let ranges = Object.keys(BMIRangeEnum).filter((key) => {
    console.log("check key ", key);
    if (
      bmi >= BMIRangeEnum[key].bmiRange.from &&
      bmi <= BMIRangeEnum[key].bmiRange.to
    ) {
      return true;
    } else return false;
  });

  // return the first matching
  if (ranges.length > 0) {
    return ranges[0];
  } else return null;
};

// compute BMR Index
export const computeBMRCalories = (
  heightInCm: number,
  weightInKg: number,
  sex: SexType,
  age: number
): number => {
  let base = 10 * weightInKg + 6.25 * heightInCm - 5 * parseInt(age);
  if (sex === SexEnum.male) {
    base += 5;
  } else {
    base -= 161;
  }
  return formatValue(base, ConversionFormat.Ceil);
};

export const computeBMRCaloriesForActivity = (
  bmrCalories: number,
  activity: ActivityType
): number => {
  return formatValue(
    bmrCalories * ActivityTypeEnum[activity].bmrMultiplier,
    ConversionFormat.Truncate
  );
};

// @flow

export function timeDurationSince(duration) {
  var seconds = duration;
  var interval = seconds / 31536000;

  if (interval > 1) {
    let year = Math.floor(interval);
    if (year == 1) return year + " year";
    else return year + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    let month = Math.floor(interval);
    if (month == 1) return month + " month";
    else return month + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    let day = Math.floor(interval);
    if (day == 1) return day + " day";
    else return day + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    let hour = Math.floor(interval);
    if (hour == 1) return hour + " hour";
    else return hour + " hours";
  }

  interval = seconds / 60;
  if (interval > 1) {
    let minute = Math.floor(interval);
    if (minute == 1) return minute + " minute";
    else return minute + " minutes";
  }

  seconds = Math.floor(seconds);
  if (seconds == 1) return seconds + " second";
  else return seconds + " seconds";
}
export function getMonthNameFromDate(str) {
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  //Set the string in the proper format(best to use ISO format ie YYYY-MM-DD or YYYY-MM-DDTHH:MM:SS)
  var d = new Date(str); //converts the string into date object
  var m = d.getMonth(); //get the value of month
  return monthNames[m]; // Print the month name
}

export function getShortMonthNameFromDate(str) {
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  //Set the string in the proper format(best to use ISO format ie YYYY-MM-DD or YYYY-MM-DDTHH:MM:SS)
  var d = new Date(str); //converts the string into date object
  var m = d.getMonth(); //get the value of month
  return monthNames[m]; // Print the month name
}

export function getDayFromDate(str) {
  //Set the string in the proper format(best to use ISO format ie YYYY-MM-DD or YYYY-MM-DDTHH:MM:SS)
  var d = new Date(str); //converts the string into date object
  return d.getDate(); //get the value of month
}

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export function hashCode(str) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function getProfileBackgroundStyle(name) {
  // compute hash of name, mod it and get the corresponding color
  if (name == null) name = "";
  return ThemeColorsEnum[hashCode(name) % Object.keys(ThemeColorsEnum).length];
}

export function generatePassword(
  length = 8,
  characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$"
) {
  Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map((x) => characters[x % characters.length])
    .join("");
}
