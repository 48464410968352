import axios from "axios";

/**
 * Provides service to make backend API calls
 */
export const SUBSCRIBE_URL = `${process.env.REACT_APP_SUBSCRIPTION_ENDPOINT}/subscribe`;
export const SUBSCRIBE_RESEND_URL = `${process.env.REACT_APP_SUBSCRIPTION_ENDPOINT}/resend`;
export const SUBSCRIBE_CONFIRM_URL = `${process.env.REACT_APP_SUBSCRIPTION_ENDPOINT}/confirm`;
export const SUBSCRIBE_UNSUBSCRIBE_URL = `${process.env.REACT_APP_SUBSCRIPTION_ENDPOINT}/unsubscribe`;

export function subscribe(email, name) {
  console.log("in : register");
  return axios.post(SUBSCRIBE_URL, {
    email,
    name,
  });
}

export function resend(email) {
  return axios.post(SUBSCRIBE_RESEND_URL, { email: email });
}

export function confirm(token) {
  console.log("in confirmRegistration");
  return axios.post(SUBSCRIBE_CONFIRM_URL, { token });
}

export function unsubscribe(token) {
  console.log("in unsubcribe");
  return axios.post(SUBSCRIBE_UNSUBSCRIBE_URL, { token });
}
