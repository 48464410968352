// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import * as uiHelpers from "app/components/screener/CompanyScreenUIHelpers";
import { mapRatingToGradeBadge } from "app/utils/UtilFunctions";
import { RatingScale } from "app/utils/Constants";

export function ScoreColumnFormatter(cellContent, row) {
  console.log("row : ,", row);
  return (
    <>
      {row.score ?? "--"} /{" "}
      {row.score ? mapRatingToGradeBadge(row.score, RatingScale.P100) : "--"}
    </>
  );
}
