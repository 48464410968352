/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch } from "react-router-dom";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { ContentRoute } from "_metronic/layout/index";
import "_metronic/_assets/sass/pages/login/classic/login-1.scss";
import ConfirmSubscription from "app/modules/Subscription/pages/ConfirmSubscription";
import Subscription from "app/modules/Subscription/pages/Subscription";
import ResendSubscription from "app/modules/Subscription/pages/ResendSubscription";
import { UnsubscribeSubscription } from "app/modules/Subscription/pages/UnsubscribeSubscription";

export function SubscriptionPage() {
  const today = new Date().getFullYear();
  const motto = process.env.REACT_APP_WEBSITE_MOTTO;
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              {/* <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/media/logos/logo-dark.png")}
                />
              </Link> */}
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  Welcome to {process.env.REACT_APP_WEBSITE_NAME}
                </h3>
                <p className="font-weight-lighter text-white opacity-80">
                  {motto}
                </p>
                <br></br>
                <h3 className="font-size-h2 mb-5 text-white">
                  {" "}
                  Service Benefits
                </h3>
                <ui>
                  <li className="font-size-h3 mb-5 text-white">
                    Free company reports and analysis
                  </li>
                  <li className="font-size-h3 mb-5 text-white">
                    Company and market condition updates
                  </li>
                  <li className="font-size-h3 mb-5 text-white">
                    Alerts delivered to your email
                  </li>
                  <li className="font-size-h3 mb-5 text-white">
                    Cancel any time!
                  </li>
                </ui>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; {today} {process.env.REACT_APP_WEBSITE_NAME}
                </div>
                <div className="d-flex">
                  <Link to="/terms" className="text-white">
                    Privacy
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Terms
                  </Link>
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}

            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute
                  path="/subscription/confirm"
                  component={ConfirmSubscription}
                />
                <ContentRoute
                  path="/subscription/subscribe"
                  component={Subscription}
                />

                <ContentRoute
                  path="/subscription/resend"
                  component={ResendSubscription}
                />
                <ContentRoute
                  path="/subscription/unsubscribe"
                  component={UnsubscribeSubscription}
                />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {today} {process.env.REACT_APP_WEBSITE_NAME}
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                {/* <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link> */}
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
