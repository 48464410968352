// @flow

export type Tool = {
  guid: number,
  title: string,
  descr: string,
  contents: string,
  tags: string,
  url: string,
  node_type_id: number,
  post_title: string,
};

export const ToolType = {
  Tool: 5,
};

export type Product = {
  guid: number,
  title: string,
  descr: string,
  contents: string,
  tags: string,
};

export type Item = {
  guid: number,
  title: string,
  descr: string,
  contents: string,
  tags: string,
  type: number,
  class: number,
  products?: Array<Product>,
};

export type Remedy = {
  index: number,
  guid: number,
  title: string,
  descr: string,
  contents: string,
  tags: string,
  items?: Array<Item>,
};

export type Problem = {
  guid: number,
  title: string,
  descr: string,
  contents: string,
  tags: string,
  seo_guid: string,
  remedies: Array<Remedy>,
};

export type Company = {
  guid: number,
  title: string,
  descr: string,
  contents: string,
  tags: string,
  seo_guid: string,
  photo: string,
  profile: CompanyProfile,
  news: Array<CompanyNews>,
  faq: Array<CompanyFaq>,
  analysis: Array<CompanyAnalysis>,
  score: CompanyScore,
  alert: Array<CompanyAlert>,
};

export type CompanyScore = {
  score: number,
  buy_rating: number,
  business_score: number,
  management_score: number,
  growth_score: number,
  profitability_score: number,
  analysis_score: number,
  valuation_score: number,
  external_score: number,
  financial_score: number,
};

export type CompanyNews = {
  title: string,
  teaser: string,
  link: string,
  news_at: string,
};
export type CompanyFaq = {
  question: string,
  answer: string,
  weight: number,
};

export type CompanyAnalysis = {
  section_type: number,
  contents: string,
  rating: number,
};

export type CompanyProfile = {
  id: number,
  website: string,
  employees: number,
  sector: string,
  industry: string,
  symbol: string,
  website: string,
  employees: number,
  public_on: string,
  sector: string,
  industry: string,
  peers: string,
  exchange: string,
  currency: string,
  exchange_short_name: string,
  phone: string,
  address: string,
  city: string,
  state: string,
  zip: string,
  country: string,
  last_div_amount: number,
  is_in_sp500: number,
  is_in_nasdaq100: number,
  shares_number: number,
  shares_outstanding: number,
  market_cap: number,
  volume_avg: number,
  public_on: string,
  last_refresh_at: string,
  number_free_perc: number,
  founded_on: number,
  beta: number,
  ceo: string,
  price_range: string,
  price_range: string,
  market_cap_class: number,
  growth_phase: number,
  dividend_yield_class: number,
};

export const RatingGrade = {
  U: "U",
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  F: "F",
  A_P: "A+",
  A_M: "A-",
  B_P: "B+",
  B_M: "B-",
  C_P: "C+",
  C_M: "C-",
  D_P: "D+",
  D_M: "D-",
};

export const RatingScale = {
  P3: 3,
  P5: 5,
  P10: 10,
  P100: 100,
};

export type CompanyAlert = {
  contents: string,
  sentiment_type: number,
  buy_rating: number,
  published_at: string,
};

export const MarketCapClass = {
  NANO_CAP: 1,
  MICRO_CAP: 2,
  SMALL_CAP: 3,
  MID_CAP: 4,
  LARGE_CAP: 5,
  MEGA_CAP: 6,
};
