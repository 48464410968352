// @flow
import { Notice } from "_metronic/_partials/controls/Notice";
import { useSubheader } from "_metronic/layout/index";

import React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useMetaTags } from "app/utils/SeoFunction";
import ProblemGrid from "../components/ProblemGrid";
import { recordPageView } from "app/utils/APIFunctions";

export const LearnHome = (): React$MixedElement => {
  const suhbeader = useSubheader();
  useMetaTags("Learn and Grow", "Simplify your investment");

  useEffect(() => {
    recordPageView("/learn/home");
    suhbeader.setTitle("Learn & Grow");
  }, [suhbeader]);

  return (
    <>
      <Notice svg="/media/svg/icons/Tools/Compass.svg">
        <h1>Learn & Grow</h1>
        Follow simple investing guides to make investment choices and grow your
        investment over time.
      </Notice>
    </>
  );
};
