// units for various types of entities
// measurementJs.convert(3.5).from(DISTANCE.KMH).to(DISTANCE.M);
// @flow
export const ConversionFormat = Object.freeze({
  None: "none",
  Round: "round",
  Round1Digit: "round1d",
  Round2Digit: "round2d",
  Ceil: "ceil",
  Floor: "floor",
  Truncate: "trunc",
});

// represents the various type of units
export const UnitType = Object.freeze({
  Speed: "Speed",
  Distance: "Distance",
  Pressure: "Pressure",
  Temperature: "Temperature",
  Duration: "Duration",
  Mass: "Mass",
});

export const Unit = {
  Speed: {
    MILES_PER_HOUR: "mph",
    KILOMETER_PER_HOUR: "kmph",
    METER_PER_SECOND: "mps",
    KNOT: "knot",
  },
  Distance: {
    MILLIMETER: "millimeter",
    INCH: "inch",
    KILOMETER: "kilometer",
    MILE: "mile",
    METER: "meter",
    YARDS: "yard",
    FEET: "feet",
    CENTIMETER: "centimeter",
  },
  Pressure: {
    HECTOPASCAL: "hectopascal",
    PASCAL: "pascal",
    BAR: "bar",
  },
  Temperature: {
    CELSIUS: "celcius",
    FAHRENHEIT: "fahrenheit",
    KELVIN: "kelvin",
  },
  Duration: {
    HOUR: "hour",
    MINUTE: "minute",
    SECOND: "second",
    MONTH: "month",
    DAY: "day",
    WEEK: "week",
    YEAR: "year",
  },
  Mass: {
    KILOGRAM: "kilogram",
    GRAM: "gram",
    MILLIGRAM: "milligram",
    OUNCE: "ounce",
    POUND: "pound",
  },
};

// various definitions
// and conversions
export const UnitDefinition = {
  Speed: {
    mph: {
      key: Unit.Speed.MILES_PER_HOUR,
      abbr: "mph",
      base: Unit.Speed.KILOMETER_PER_HOUR,
      factor: 1.609344,
      name: "miles/hour",
      plural: "miles/hour",
      abbrPlural: "mph",
    },
    kph: {
      key: Unit.Speed.KILOMETER_PER_HOUR,
      abbr: "km/h",
      base: null,
      name: "kiolmeters/hour",
      plural: "km/h",
      abbrPlural: "km/h",
    },
    mps: {
      key: Unit.Speed.METER_PER_SECOND,
      abbr: "m/s",
      base: Unit.Speed.KILOMETER_PER_HOUR,
      factor: 3.6,
      name: "meters/sec",
      plural: "m/s",
      abbrPlural: "m/s",
    },
    knot: {
      key: Unit.Speed.KNOT,
      abbr: "kn",
      base: Unit.Speed.KILOMETER_PER_HOUR,
      factor: 1.852,
      name: "Knots",
      plural: "Knots",
      abbrPlural: "kns",
    },
  },
  Distance: {
    kilometer: {
      key: Unit.Distance.KILOMETER,
      abbr: "km",
      base: Unit.Distance.METER,
      factor: 1000,
      name: "Kilometer",
      plural: "Kilometers",
      abbrPlural: "km",
    },
    meter: {
      key: Unit.Distance.METER,
      abbr: "m",
      base: null, // equals factor of 1
      name: "Meter",
      plural: "Meters",
      abbrPlural: "m",
    },
    millimeter: {
      key: Unit.Distance.MILLIMETER,
      abbr: "mm",
      base: Unit.Distance.METER,
      factor: 0.001,
      name: "Millimeter",
      plural: "Millimeters",
      abbrPlural: "mm",
    },
    inch: {
      key: Unit.Distance.INCH,
      abbr: "in",
      base: Unit.Distance.METER,
      factor: 0.0254,
      name: "Inch",
      plural: "Inches",
      abbrPlural: "in",
    },
    feet: {
      key: Unit.Distance.FEET,
      abbr: "ft",
      base: Unit.Distance.METER,
      factor: 0.3048,
      name: "Feet",
      abbrPlural: "ft",
    },
    centimeter: {
      key: Unit.Distance.CENTIMETER,
      abbr: "cm",
      base: Unit.Distance.METER,
      factor: 0.01,
      name: "Centimeter",
      plural: "Centimeters",
      abbrPlural: "cm",
    },
    mile: {
      key: Unit.Distance.MILE,
      abbr: "M",
      base: Unit.Distance.METER,
      factor: 1609.34,
      name: "Mile",
      plural: "Miles",
      abbrPlural: "M",
    },
  },
  Pressure: {
    hectopascal: {
      key: Unit.Pressure.HECTOPASCAL,
      abbr: "hPa",
      base: Unit.Pressure.PASCAL,
      factor: 100,
      name: "Hectopascal",
      plural: "Hectopascals",
      abbrPlural: "hPa",
    },
    pascal: {
      key: Unit.Pressure.PASCAL,
      abbr: "Pa",
      base: null,
      name: "Pascal",
      plural: "Pascals",
      abbrPlural: "Pa",
    },
    bar: {
      key: Unit.Pressure.BAR,
      abbr: "bar",
      base: Unit.Pressure.PASCAL,
      factor: 1000000,
      name: "Bar",
      plural: "Bars",
      abbrPlural: "bar",
    },
  },
  Temperature: {
    celcius: {
      key: Unit.Temperature.CELSIUS,
      abbr: "C",
      base: null,
      name: "Celcius",
      plural: "Celcius",
      abbrPlural: "C",
    },
    fahrenheit: {
      key: Unit.Temperature.FAHRENHEIT,
      abbr: "F",
      base: Unit.Temperature.CELSIUS,
      factor: function(value, reverse) {
        if (reverse) {
          return value * 1.8 + 32;
        }

        return ((value - 32) * 5) / 9;
      },
      name: "Fahrenheit",
      plural: "Fahrenheits",
      abbrPlural: "F",
    },
    kelvin: {
      key: Unit.Temperature.KELVIN,
      abbr: "K",
      base: Unit.Temperature.CELSIUS,
      factor: function(value, reverse) {
        /**
         * Really strange rounding error:
         * (100 - 273.15) gives -173.14999999999998 (tested in Chrome 26.0.1410.63)
         *
         * Following workarounds:
         */
        if (reverse) {
          return parseFloat((value + 273 + 0.15).toFixed(10));
        }

        return value - 273 - 0.15;
      },
      name: "Kelvin",
      plural: "Kelvins",
      abbrPlural: "K",
    },
  },
  Duration: {
    hour: {
      key: Unit.Duration.HOUR,
      abbr: "hr",
      base: Unit.Duration.SECOND,
      factor: 3600,
      name: "Hour",
      plural: "Hours",
      abbrPlural: "hrs",
    },
    minute: {
      key: Unit.Duration.MINUTE,
      abbr: "min",
      base: Unit.Duration.SECOND,
      factor: 60,
      name: "Minute",
      plural: "Minutes",
      abbrPlural: "mins",
    },
    second: {
      key: Unit.Duration.SECOND,
      abbr: "sec",
      base: null,
      factor: 1,
      name: "Second",
      plural: "Seconds",
      abbrPlural: "secs",
    },
    day: {
      key: Unit.Duration.DAY,
      abbr: "d",
      base: Unit.Duration.SECOND,
      factor: 86400,
      name: "Day",
      plural: "Days",
      abbrPlural: "d",
    },
    week: {
      key: Unit.Duration.WEEK,
      abbr: "wk",
      base: Unit.Duration.DAY,
      factor: 7,
      name: "Week",
      plural: "Weeks",
      abbrPlural: "wks",
    },
    month: {
      key: Unit.Duration.MONTH,
      abbr: "mon",
      base: Unit.Duration.WEEK,
      factor: 4,
      name: "Month",
      plural: "Months",
      abbrPlural: "mos",
    },
    year: {
      key: Unit.Duration.YEAR,
      abbr: "yr",
      base: Unit.Duration.MONTH,
      factor: 12,
      name: "Year",
      plural: "Years",
      abbrPlural: "yrs",
    },
  },
  Mass: {
    kilogram: {
      key: Unit.Mass.KILOGRAM,
      abbr: "kg",
      base: Unit.Mass.GRAM,
      factor: 1000,
      name: "Kilogram",
      plural: "Kilograms",
      abbrPlural: "kg",
    },
    gram: {
      key: Unit.Mass.GRAM,
      abbr: "g",
      base: null,
      factor: 1,
      name: "Gram",
      plural: "Grams",
      abbrPlural: "g",
    },
    milligram: {
      key: Unit.Mass.MILLIGRAM,
      abbr: "mg",
      base: Unit.Mass.GRAM,
      factor: 0.001,
      name: "Milligram",
      plural: "Milligrams",
      abbrPlural: "mg",
    },
    ounce: {
      key: Unit.Mass.OUNCE,
      abbr: "oz",
      base: Unit.Mass.GRAM,
      factor: 28.3495,
      name: "Ounce",
      plural: "Ounces",
      abbrPlural: "oz",
    },
    pound: {
      key: Unit.Mass.POUND,
      abbr: "lb",
      base: Unit.Mass.GRAM,
      factor: 453.592,
      name: "Pounds",
      plural: "Pound",
      abbrPlural: "lb",
    },
  },
};

// format based on input
export const formatValue = (
  value: number,
  formatType: $Values<typeof ConversionFormat> = ConversionFormat.None
): number => {
  if (formatType === ConversionFormat.None) return value;
  if (formatType === ConversionFormat.Round) {
    return Math.round(value);
  }
  if (formatType === ConversionFormat.Round1Digit) {
    return Math.round(value * 10) / 10;
  }
  if (formatType === ConversionFormat.Round2Digit) {
    return Math.round(value * 100) / 100;
  }
  if (formatType === ConversionFormat.Ceil) {
    return Math.ceil(value);
  }
  if (formatType === ConversionFormat.Floor) {
    return Math.floor(value);
  }
  if (formatType === ConversionFormat.Truncate) {
    return parseInt(value);
  }
  throw new Error("Format type not defined");
};

// main class
export class MeasureJS {
  unitType = UnitType.Mass;
  constructor(unitType) {
    this.unitType = unitType;
  }

  convert(
    value: number,
    inputUnit: string,
    outputUnit: string,
    formatType = ConversionFormat.None
  ): number {
    console.log("Convert", this.unitType, value, inputUnit, outputUnit);
    if (UnitDefinition[this.unitType]) {
      console.log("Has definition", inputUnit, outputUnit);

      var inputDef = UnitDefinition[this.unitType][inputUnit],
        outputDef = UnitDefinition[this.unitType][outputUnit];

      if (inputDef && outputDef) {
        console.log("Has both input and output def");
        if (inputDef.base === outputUnit) {
          console.log("inputDef.base === outputUnit");
          if (typeof inputDef.factor === "function") {
            return formatValue(inputDef.factor(value), formatType);
          }

          return formatValue(value * inputDef.factor, formatType);
        } else if (inputDef.key === outputDef.base) {
          console.log("inputDef.key === outputDef.base");
          if (typeof outputDef.factor === "function") {
            return formatValue(outputDef.factor(value, true), formatType);
          }

          return formatValue(value / outputDef.factor, formatType);
        } else {
          // We're here b/c neither input nor out type is base type to which we could directly convert

          var baseType = inputDef.base || outputDef.base,
            baseValue = 0;
          if (typeof baseType === "undefined") {
            throw new Error("Invalid base type");
          }

          if (baseType === inputDef.base) {
            console.log("input def has base");
            baseValue = new MeasureJS(this.unitType).convert(
              value * inputDef.factor,
              inputDef.base,
              outputDef.key
            );
            inputUnit = inputDef.base;
          } else if (baseType === outputDef.base) {
            console.log("**output def has base");
            baseValue = new MeasureJS(this.unitType).convert(
              value * inputDef.factor,
              outputDef.base,
              outputDef.key
            );
          }
          // TODO : need to review fix and test
          // if (baseType === Unit.Temperature.CELSIUS) {
          //   return parseFloat(self.convert(baseValue).toFixed(10));
          // }

          return formatValue(baseValue, formatType);
        }
      }
    }
    //console.log("Definition of unit not found");
    throw new Error("Definition of unit not found");
  }
}

// base objects
export const distanceConverter = new MeasureJS(UnitType.Distance);
export const massConverter = new MeasureJS(UnitType.Mass);

// exports
export type UnitTypeType = $Keys<typeof UnitType>;
export type ConversionFormatType = $Values<typeof ConversionFormat>;
export type SpeedType = $Values<typeof Unit.Speed>;
export type DistanceType = $Values<typeof Unit.Distance>;
export type PressureType = $Values<typeof Unit.Pressure>;
export type MassType = $Values<typeof Unit.Mass>;
export type TemperatureType = $Values<typeof Unit.Temperature>;
export type DurationType = $Values<typeof Unit.Duration>;

// helper functions
export const fromKgToLb = (
  value: number,
  formatType: ConversionFormatType = ConversionFormat.None
): number =>
  new MeasureJS(UnitType.Mass).convert(
    value,
    Unit.Mass.KILOGRAM,
    Unit.Mass.POUND,
    formatType
  );

export const fromLbToKg = (
  value: number,
  formatType: ConversionFormatType = ConversionFormat.None
): number =>
  new MeasureJS(UnitType.Mass).convert(
    value,
    Unit.Mass.POUND,
    Unit.Mass.KILOGRAM,
    formatType
  );

export const fromInchToCm = (
  value: number,
  formatType: ConversionFormatType = ConversionFormat.None
): number =>
  new MeasureJS(UnitType.Distance).convert(
    value,
    Unit.Distance.INCH,
    Unit.Distance.CENTIMETER,
    formatType
  );

export const fromFeetToInch = (
  value: number,
  formatType: ConversionFormatType = ConversionFormat.None
): number =>
  new MeasureJS(UnitType.Distance).convert(
    value,
    Unit.Distance.FEET,
    Unit.Distance.INCH,
    formatType
  );

export const fromCmToInch = (
  value: number,
  formatType: ConversionFormatType = ConversionFormat.None
): number =>
  new MeasureJS(UnitType.Distance).convert(
    value,
    Unit.Distance.CENTIMETER,
    Unit.Distance.INCH,
    formatType
  );

export const fromFeetInchesToCm = (
  feet: number,
  inches: number,
  formatType: ConversionFormatType = ConversionFormat.None
): number => {
  let converter = new MeasureJS(UnitType.Distance);
  return formatValue(
    converter.convert(feet, Unit.Distance.FEET, Unit.Distance.CENTIMETER) +
      converter.convert(inches, Unit.Distance.INCH, Unit.Distance.CENTIMETER),
    formatType
  );
};

export const fromInchToMeter = (
  inches: number,
  formatType: ConversionFormatType = ConversionFormat.None
): number => {
  let converter = new MeasureJS(UnitType.Distance);
  return converter.convert(inches, Unit.Distance.INCH, Unit.Distance.METER);
};

// TODO : implement it
// add various 2nd level elements to array as key
export const getUnit = (unit) => {
  return null;
};
