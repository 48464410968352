import { Tooltip } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
/**
 * Toolbar to show on a tool
 * @param {*} param0
 * @returns
 */
export function ToolToolbar() {
  const isCodeBlockShown = false;
  const isCopySucceed = true;

  const toggleShowCode = () => {};
  const copyCode = () => {};
  return (
    <>
      <Tooltip title="View code">
        <span
          className={`example-toggle ${clsx({
            "example-toggled": isCodeBlockShown,
          })}`}
          onClick={toggleShowCode}
        />
      </Tooltip>
      <Tooltip title="Copy code">
        <span
          className={`example-copy ${clsx({
            "example-copied": isCopySucceed,
          })}`}
          onClick={copyCode}
        />
      </Tooltip>
    </>
  );
}
