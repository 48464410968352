import objectPath from "object-path";
import React, { useMemo } from "react";
import { useHtmlClassService } from "../../layout";
import { Demo1Dashboard } from "./Demo1Dashboard";
import { Demo2Dashboard } from "./Demo2Dashboard";
import { Demo3Dashboard } from "./Demo3Dashboard";

export function Dashboard() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      demo: objectPath.get(uiService.config, "demo"),
    };
  }, [uiService]);
  return (
    <>
      {layoutProps.demo === "demo1" && <Demo1Dashboard />}
      {layoutProps.demo === "demo2" && <Demo2Dashboard />}
      {layoutProps.demo === "demo3" && <Demo3Dashboard />}
    </>
  );
}
