import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const FreezeFrostCalculator = () => {
  const [zipcode, setZipcode] = useState("");
  const [firstFreeze, setFirstFreeze] = useState("");
  const [lastFreeze, setLastFreeze] = useState("");

  const apiKey = "YOUR_NCEI_WEATHER_API_KEY";

  const handleZipcodeChange = (e) => {
    setZipcode(e.target.value);
  };

  const getFreezeDates = () => {
    axios
      .get(
        `https://www.ncdc.noaa.gov/cdo-web/api/v2/data?datasetid=GHCND&datatypeid=TMIN&locationid=ZIP:${zipcode}&startdate=2021-01-01&enddate=2021-12-31`,
        {
          headers: {
            token: apiKey,
          },
        }
      )
      .then((response) => {
        const data = response.data.results;
        const freezes = data.filter((entry) => entry.value <= 32);
        if (freezes.length > 0) {
          const first = freezes[0].date;
          const last = freezes[freezes.length - 1].date;
          setFirstFreeze(first);
          setLastFreeze(last);
        } else {
          setFirstFreeze("Not found");
          setLastFreeze("Not found");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div>
      <h2>Freeze/Frost Date Calculator</h2>
      <Form>
        <Form.Group controlId="zipcode">
          <Form.Label>Enter Zipcode:</Form.Label>
          <Form.Control
            type="text"
            value={zipcode}
            onChange={handleZipcodeChange}
          />
        </Form.Group>
        <Button variant="primary" onClick={getFreezeDates}>
          Calculate
        </Button>
      </Form>
      <div>
        <label>First Freeze Date:</label>
        <span>{firstFreeze}</span>
      </div>
      <div>
        <label>Last Freeze Date:</label>
        <span>{lastFreeze}</span>
      </div>
    </div>
  );
};

export default FreezeFrostCalculator;
