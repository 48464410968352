import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCompanyScreenUIContext } from "app/components/screener/CompanyScreenUIContext";

const prepareFilter = (queryParams, values) => {
  const { buyRating, quantScore, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by rating
  //filter.buyRating = buyRating !== "" ? +buyRating : undefined;
  // Filter by grade
  //filter.quantScore = quantScore !== "" ? +quantScore : undefined;
  // Filter by all fields
  filter.buyRating = buyRating;
  filter.quantScore = quantScore;
  filter.searchText = searchText;
  // if (searchText) {
  //   filter.title = searchText;
  //   filter.sector = searchText;
  //   filter.industry = searchText;
  //   filter.buyRating = searchText;
  // }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CompanyScreenFilter({ listLoading }) {
  // Customers UI Context
  const companyScreenUIContext = useCompanyScreenUIContext();
  const companyScreenUIProps = useMemo(() => {
    return {
      queryParams: companyScreenUIContext.queryParams,
      setQueryParams: companyScreenUIContext.setQueryParams,
    };
  }, [companyScreenUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      companyScreenUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, companyScreenUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      companyScreenUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          quantScore: "", // values => All=""/Susspended=0/Active=1/Pending=2
          buyRating: "", // values => All=""/Business=0/Individual=1
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Quant Score"
                  name="quantScore"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("quantScore", e.target.value);
                    handleSubmit();
                  }}
                  value={values.grade}
                >
                  <option value="">All</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                  <option value="F">F</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Quant Score
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="buyRating"
                  placeholder="Filter by Buy Rating"
                  // TODO: Change this code
                  onChange={(e) => {
                    setFieldValue("buyRating", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.rating}
                >
                  <option value="">All</option>
                  <option value="5">Strong Buy</option>
                  <option value="4">Buy</option>
                  <option value="3">Hold</option>
                  <option value="2">Sell</option>
                  <option value="1">Strong Sell</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Buy Rating
                </small>
              </div>

              <div className="col-lg-2">
                <input
                  grade="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
