// @flow
import { runApiQuery } from "app/utils/APIFunctions";
import { gql } from "graphql-request";
import React, { useEffect, useState } from "react";
import { Card, CardColumns } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { Tool } from "app/utils/Constants";

const GET_TOOLS_QUERY = gql`
  query {
    toolQuery {
      __typename
      guid
      title
      descr
      contents
      tags
      url
    }
  }
`;

// take in the props
type Props = {
  toolType: number,
};

export default function ToolGrid({ toolType }: Props): React$MixedElement {
  const [tools, setTools] = useState(null);

  useEffect(() => {
    const loadTools = async () => {
      if (tools == null) {
        const data = await runApiQuery(GET_TOOLS_QUERY, {});
        //    console.log(data);
        if (data.toolQuery) {
          setTools(data.toolQuery);
        }
      }
    };
    loadTools();
  }, [toolType, tools]);
  return (
    <>
      {tools && (
        <CardColumns>
          {tools.map((tool: Tool) => {
            return (
              <Link to={tool.url} key={tool.guid}>
                <Card>
                  {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
                  <Card.Body>
                    <Card.Title className="text-dark">
                      <i className="flaticon-paper-plane-1 text-primary icon-lg"></i>{" "}
                      {tool.title}
                    </Card.Title>
                    <Card.Text className="text-dark-50">{tool.descr}</Card.Text>
                  </Card.Body>
                  {/* <Card.Footer>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </Card.Footer> */}
                </Card>
              </Link>
            );
          })}
        </CardColumns>
      )}
    </>
  );
}
