import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "_metronic/_partials/controls";
import { useCompanyScreenUIContext } from "./CompanyScreenUIContext";
import { CompanyScreenTable } from "app/components/screener/CompanyScreenTable";
import { CompanyScreenFilter } from "app/components/screener/CompanyScreenFilter";

export function CompanyScreenCard() {
  const companyScreenUIContext = useCompanyScreenUIContext();
  const companyScreenUIProps = useMemo(() => {
    return {
      ids: companyScreenUIContext.ids,
    };
  }, [companyScreenUIContext]);

  return (
    <Card>
      <CardHeader title="Company Screener">
        {/* <CardHeaderToolbar>
          <button type="button" className="btn btn-primary" onClick={{}}>
            New Company
          </button>
        </CardHeaderToolbar> */}
      </CardHeader>
      <CardBody>
        <CompanyScreenFilter />
        <CompanyScreenTable />
      </CardBody>
    </Card>
  );
}
