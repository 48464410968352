/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Badge } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter } from "_metronic/_partials/controls/Card";

export function SearchResult({ data }) {
  console.log("data", data);
  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return (
      <div
        className="quick-search-result"
        style={{
          padding: "10px",
          marginTop: "10px",
        }}
      >
        {/* begin: Section */}

        <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
          No Results Found.
        </div>
      </div>
    );
  }

  const getFooter = (node) => {
    return (
      <>
        {node.tags &&
          node.tags.split(",").map((tag) => (
            <span id="one" key={tag}>
              <Badge variant="light">{tag}</Badge>
              &nbsp;
            </span>
          ))}
      </>
    );
  };

  return (
    <div>
      {/* begin: Section */}
      <div
        className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2 "
        style={{
          marginTop: "10px",
        }}
      >
        Results
      </div>
      {data.map((result) => (
        <Card style={{ padding: 0 }}>
          <CardBody>
            <div key={result.guid}>
              <div className="d-flex align-items-center flex-grow-1">
                <div className="symbol symbol-45 bg-transparent flex-shrink-0">
                  <SVG src={result.iconImage}></SVG>
                </div>
                <div className="d-flex flex-column ml-3 mt-2">
                  <Link
                    className="font-weight-bold text-dark text-hover-primary"
                    to={result.url}
                  >
                    {result.text}
                  </Link>
                  <span className="font-size-sm font-weight-bold text-muted">
                    {result.type}
                  </span>
                </div>
              </div>
            </div>
          </CardBody>
          <CardFooter style={{ padding: "1rem 1.25rem" }}>
            {getFooter(result)}
          </CardFooter>
        </Card>
      ))}
      {/* end: Section */}
    </div>
  );
}
