// @flow
import { runApiQuery } from "app/utils/APIFunctions";
import { gql } from "graphql-request";
import React, { useEffect, useState } from "react";
import { Card, CardColumns } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { Company } from "../utils/Constants";

const GET_COMPANY_QUERY = gql`
  query {
    companyQuery {
      __typename
      guid
      title
      descr
      tags
      seo_guid
      photo
      symbol
    }
  }
`;

const GET_COMPANY_BY_CATEGORY_QUERY = gql`
  query($categoryId: ID!) {
    companyQuery(category_id: $categoryId) {
      __typename
      guid
      title
      descr
      tags
      seo_guid
      photo
      symbol
    }
  }
`;
// take in the props
type Props = {
  categoryId?: number,
};

/**
 * Represents the company grid
 * @param {} param0
 * @returns
 */
export default function CompanyList({ categoryId }: Props): React$MixedElement {
  const [companies, setCompanies] = useState(null);

  useEffect(() => {
    const loadCompanies = async () => {
      if (companies == null) {
        const variables = {
          typeId: categoryId,
        };
        let data = null;
        if (categoryId) {
          data = await runApiQuery(GET_COMPANY_BY_CATEGORY_QUERY, variables);
        } else {
          data = await runApiQuery(GET_COMPANY_QUERY);
        }
        if (data.companyQuery) {
          setCompanies(data.companyQuery);
        }
      }
    };
    loadCompanies();
  }, [categoryId, companies]);
  return (
    <>
      {companies &&
        companies.map((company: Company) => {
          return (
            <>
              {company.symbol}
              <img src={company.photo} height={50}></img> {company.title}
            </>
          );
        })}
    </>
  );
}
