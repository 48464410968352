import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CompanyScreenUIHelpers";

const CompanyScreenUIContext = createContext();

export function useCompanyScreenUIContext() {
  return useContext(CompanyScreenUIContext);
}

export const CompanyScreenUIConsumer = CompanyScreenUIContext.Consumer;

export function CompanyScreenUIProvider({ customersUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCompany = {
    id: undefined,
    symbol: "",
    title: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCompany,
  };

  return (
    <CompanyScreenUIContext.Provider value={value}>
      {children}
    </CompanyScreenUIContext.Provider>
  );
}
