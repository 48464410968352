import axios from "axios";
import store from "./authRedux";

/**
 * Provides service to make backend API calls
 */
export const LOGIN_URL = `${process.env.REACT_APP_AUTH_ENDPOINT}/login`;
export const REGISTER_URL = `${process.env.REACT_APP_USER_ENDPOINT}/register`;
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_USER_ENDPOINT}/forgot-password`;
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_USER_ENDPOINT}/reset-password`;
export const CONFIRM_REGISTRATION_URL = `${process.env.REACT_APP_USER_ENDPOINT}/confirm-registration`;
export const PROFILE_URL = `${process.env.REACT_APP_USER_ENDPOINT}/profile`;

export function login(username, password) {
  console.log("in : login");
  return axios.post(LOGIN_URL, { username, password });
}

export function register(email, name, username, password) {
  console.log("in : register");
  return axios.post(REGISTER_URL, {
    email,
    name: name,
    username,
    password,
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { username: email });
}

export function resetPassword(token, password) {
  return axios.post(RESET_PASSWORD_URL, { token, password });
}

export function confirmRegistration(token) {
  console.log("in confirmRegistration");
  return axios.post(CONFIRM_REGISTRATION_URL, { token });
}

export function getUserByToken(authToken) {
  // Authorization head should be fulfilled in interceptor.
  console.log("in : getUserByToken");
  console.log("store", store.getState());

  console.log(authToken);

  authToken = localStorage.getItem("TOKEN");
  console.log("localstorage", authToken);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  return axios.post(
    PROFILE_URL,
    {},
    {
      headers: headers,
    }
  );
}
