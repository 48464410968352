// @flow
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen } from "../_metronic/layout";
import { Routes } from "../app/Routes";
import { AuthInit } from "./modules/Auth/index";
import { Layout } from "_metronic/layout/index";
import ReactGA from "react-ga";
import React from "react";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

// Google analytics UA tracking id
// const TRACKING_ID = "UA-1296266-55"; // YOUR_OWN_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

import TagManager from "react-gtm-module";
import { recordPageView } from "app/utils/APIFunctions";
const tagManagerArgs = {
  gtmId: "G-PPZBKEW1M0",
};
TagManager.initialize(tagManagerArgs);

type Props = {
  store: any,
  persistor: any,
  basename: string,
};

// Main App
export default function App({
  store,
  persistor,
  basename,
}: Props): React$MixedElement {
  useEffect(() => {
    recordPageView("/");
  }, []);

  return (
    <>
      {/* Provide Redux store */}
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <AuthInit>
                  <Layout>
                    <Routes />
                  </Layout>
                </AuthInit>
              </I18nProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </>
  );
}
