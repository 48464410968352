import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const GrassSeedCalculator = ({ tool, toolbar }: Props) => {
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [grassType, setGrassType] = useState("Bermuda Grass");
  const [grassSeedRequired, setGrassSeedRequired] = useState(null);
  const [costPerPound, setCostPerPound] = useState("");
  const [totalCost, setTotalCost] = useState(null);
  const [lawnType, setLawnType] = useState("New Lawn"); // New Lawn or Overseeding

  // Recommended seeding rates for different grass types (sq. ft. per pound)
  const grassTypesData = {
    "Bermuda Grass": 1000,
    "Kentucky Bluegrass": 2000,
    "Fescue Grass": 1500,
    "Zoysia Grass": 500,
    // Add more grass types and their recommended seeding rates here
  };

  const handleCalculate = () => {
    const lawnArea = parseFloat(length) * parseFloat(width);
    const recommendedSeedingRate = grassTypesData[grassType];
    let grassSeedRequired = lawnArea / parseFloat(recommendedSeedingRate);

    // Reduce grass seed requirements for overseeding by half
    if (lawnType === "Overseeding") {
      grassSeedRequired /= 2;
    }

    setGrassSeedRequired(grassSeedRequired.toFixed(2));

    if (costPerPound) {
      const totalCost = (grassSeedRequired * parseFloat(costPerPound)).toFixed(
        2
      );
      setTotalCost(totalCost);
    } else {
      setTotalCost(null);
    }
  };
  console.log("in grass");
  return (
    <Container>
      <h2>Grass Seed Calculator</h2>
      <Form>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Length of Lawn (feet):</Form.Label>
              <Form.Control
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Width of Lawn (feet):</Form.Label>
              <Form.Control
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Type of Grass Seed:</Form.Label>
              <Form.Control
                as="select"
                value={grassType}
                onChange={(e) => setGrassType(e.target.value)}
              >
                <option value="Bermuda Grass">Bermuda Grass</option>
                <option value="Kentucky Bluegrass">Kentucky Bluegrass</option>
                <option value="Fescue Grass">Fescue Grass</option>
                <option value="Zoysia Grass">Zoysia Grass</option>
                {/* Add more grass types here */}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Cost per Pound of Grass Seed:</Form.Label>
              <Form.Control
                type="number"
                value={costPerPound}
                onChange={(e) => setCostPerPound(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Lawn Type:</Form.Label>
          <Form.Check
            type="radio"
            label="New Lawn"
            name="lawnType"
            value="New Lawn"
            checked={lawnType === "New Lawn"}
            onChange={() => setLawnType("New Lawn")}
          />
          <Form.Check
            type="radio"
            label="Overseeding"
            name="lawnType"
            value="Overseeding"
            checked={lawnType === "Overseeding"}
            onChange={() => setLawnType("Overseeding")}
          />
        </Form.Group>
        <Button onClick={handleCalculate}>Calculate Grass Seed Required</Button>
        {grassSeedRequired !== null && (
          <div>
            <p>Grass Seed Required: {grassSeedRequired} pounds</p>
            {totalCost !== null && <p>Total Cost of Project: ${totalCost}</p>}
          </div>
        )}
      </Form>
    </Container>
  );
};

export default GrassSeedCalculator;
