// @flow
import { Notice } from "_metronic/_partials/controls/Notice";
import { useSubheader } from "_metronic/layout/index";

import React from "react";
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useMetaTags } from "app/utils/SeoFunction";
import { runApiQuery } from "app/utils/APIFunctions";
import { gql } from "graphql-request";
import { Card, CardColumns } from "react-bootstrap";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { Badge } from "react-bootstrap";
import AccountSettings from "app/modules/UserProfile/AccountSettings";
import { ProfileOverview } from "app/modules/UserProfile/ProfileOverview";
import { MyQuestions } from "./MyQuestions";
import { ProfileCard } from "app/modules/UserProfile/components/ProfileCard";
import ChangePassword from "app/modules/UserProfile/ChangePassword";
import EmailSettings from "app/modules/UserProfile/NotificationsSettings";
import PersonaInformation from "app/modules/UserProfile/PersonaInformation";
import ProtectedRoute from "../modules/Auth/ProtectedRoute";
import NotificationsSettings from "app/modules/UserProfile/NotificationsSettings";
import { MyAccountHome } from "app/my/MyAccountHome";
import PreferencesSettings from "app/modules/UserProfile/PreferencesSettings";
import DeactivateAccount from "app/my/DeactivateAccount";

const FRAGMENT_MY_ACCOUNT_FIELDS = gql`
  fragment MyAccountFields on MyAccount {
    __typename
    user {
      username
      email
      profile {
        profile_photo
        name
        headline
        website
      }
      submissionStats {
        total_correct
        total_wrong
        percentage_correct
        total_pending
      }
      achievementStats {
        points
        score
        badges
        level
        rank
        band
      }
    }
  }
`;

const GET_MY_ACCOUNT_QUERY = gql`
  query {
    myAccount {
      ...MyAccountFields
    }
  }
  ${FRAGMENT_MY_ACCOUNT_FIELDS}
`;

export const MyAccount = (): React$MixedElement => {
  const suhbeader = useSubheader();
  useMetaTags("My Account");
  const [myAccountData, setMyAccountData] = useState(null);

  useEffect(() => {
    const loadMyAccount = async () => {
      let data = await runApiQuery(GET_MY_ACCOUNT_QUERY);
      if (data.myAccount) {
        setMyAccountData(data.myAccount);
      }
    };
    loadMyAccount();
  }, []);

  useEffect(() => {
    ReactGA.pageview("/my/account/home");
    suhbeader.setTitle("MyAccount");
  }, [suhbeader]);

  return (
    <>
      <div className="d-flex flex-row">
        <ProfileCard user={myAccountData?.user}></ProfileCard>
        <div className="flex-row-fluid ml-lg-8">
          <Switch>
            <Route
              path="/my/account/home"
              exact={true}
              render={(props) => (
                <MyAccountHome
                  {...props}
                  submissionStats={myAccountData?.user?.submissionStats}
                  achievementStats={myAccountData?.user?.achievementStats}
                />
              )}
            />

            <Route path="/my/account/submissions" component={MyQuestions} />
            <Route path="/my/account/settings" component={AccountSettings} />
            <Route
              path="/my/account/preferences"
              component={PreferencesSettings}
            />
            <Route
              path="/my/account/change-password"
              component={ChangePassword}
            />
            <Route
              path="/my/account/notifications"
              component={NotificationsSettings}
            />
            <Route
              path="/my/account/personal"
              exact
              render={(props) => (
                <PersonaInformation
                  {...props}
                  profile={myAccountData?.user?.profile}
                />
              )}
            />

            <Route
              path="/my/account/deactivate"
              component={DeactivateAccount}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};
