export const defaultSorted = [{ dataField: "rank", order: "asc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
];
export const initialFilter = {
  filter: {
    buyRating: "",
    quantScore: "",
    searchText: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "rank",
  pageNumber: 1,
  pageSize: 25,
};
