// @flow
import React from "react";
import { Badge } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "_metronic/_partials/controls/Card";
import type { Problem } from "../utils/Constants";

/**
 * Represent a Problem
 * @returns
 */

type Props = {
  problem: Problem,
};

const getHeader = (problem: Problem) => {
  return (
    <>
      <i className="flaticon-paper-plane-1 text-danger icon-lg"></i>{" "}
      <span> {problem.title}</span>
      {/* <Badge variant="danger">Problem</Badge> <span> {problem.title}</span> */}
    </>
  );
};

const getFooter = (problem: Problem) => {
  return (
    <>
      {problem.tags &&
        problem.tags.split(",").map((tag) => (
          <span id="one" key={tag}>
            <Badge variant="light">{tag}</Badge>
            &nbsp;
          </span>
        ))}
    </>
  );
};

const ProblemView = ({ problem }: Props): React$MixedElement => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Card>
            <CardHeader title={getHeader(problem)} />{" "}
            <CardBody>
              <div>{ReactHtmlParser(problem.contents)}</div>
            </CardBody>
            <CardFooter>{getFooter(problem)} </CardFooter>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ProblemView;
