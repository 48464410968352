import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";

/**
 * Represents function related to user authentication
 */
export function useIsUserLoggedIn() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return isAuthorized;
}
