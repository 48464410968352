import React, { useState } from "react";
export function AlertMessage({
  title,
  type,
  children,
}: props): React.ReactNode {
  let icon = "warning";
  if (type) {
    if (type === "danger") {
      icon = "warning";
    } else if (type === "success") {
      icon = "information";
    } else if (type === "info") {
      icon = "information";
    } else if (type === "warning") {
      icon = "warning-sign";
    }
  }
  return (
    <>
      {title && (
        <div>
          <h3 className="font-size-h1">{title}</h3>
          <div className={`alert  alert-custom alert-${type}`} role="alert">
            <div className="alert-icon">
              <i className={`flaticon-${icon}`}></i>
            </div>
            <div className="alert-text">{children}</div>
          </div>
        </div>
      )}
      {!title && (
        <div className={`alert  alert-custom alert-${type}`} role="alert">
          <div className="alert-icon">
            <i className={`flaticon-${icon}`}></i>
          </div>
          <div className="alert-text">{children}</div>
        </div>
      )}
    </>
  );
}
