import React, { createContext, useContext, useMemo } from "react";
import { getInitLayoutConfig } from "./LayoutConfig";
import { HtmlClassService } from "./HTMLClassService";

const LAYOUT_CONFIG_KEY =
  process.env.REACT_APP_LAYOUT_CONFIG_KEY || "LayoutConfig";

/***
 * Returns the layout config as store in the local storage.
 */
function getConfig() {
  // if stored locally return it
  const ls = localStorage.getItem(LAYOUT_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }

  // else return default one
  return getInitLayoutConfig();
}

// Side effect
// when the config is changed, store it locally and reload the window
export function setLayoutConfig(config) {
  localStorage.setItem(LAYOUT_CONFIG_KEY, JSON.stringify(config));
  window.location.reload();
}

const HtmlClassServiceContext = createContext();

/**
 *
 *
 * @export
 * @returns Layout context from 'localStorage'
 */
export function useHtmlClassService() {
  return useContext(HtmlClassServiceContext);
}

export function withHtmlClassService(Component) {
  class WithHtmlClassService extends React.Component {
    static displayName = `WithHtmlClassService(${Component.displayName ||
      Component.name})`;

    static contextType = HtmlClassServiceContext;

    render() {
      return <Component {...this.props} htmlClassService={this.context} />;
    }
  }

  return WithHtmlClassService;
}

export const HtmlClassServiceConsumer = HtmlClassServiceContext.Consumer;

// The provider of the Metronic layout
export function MetronicLayoutProvider({ children }) {
  // cache the layout config
  const lc = useMemo(getConfig, []);

  // cache the hml class service and change when the service changes
  const hcs = useMemo(() => {
    const service = new HtmlClassService();
    service.setConfig(lc);
    return service;
  }, [lc]);

  return (
    <HtmlClassServiceContext.Provider value={hcs}>
      {children}
    </HtmlClassServiceContext.Provider>
  );
}
