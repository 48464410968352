// @flow
import { useSubheader } from "_metronic/layout/index";
import { Notice } from "_metronic/_partials/controls/Notice";
import ReactGA from "react-ga";
import React from "react";
import { useEffect } from "react";
import { useMetaTags } from "app/utils/SeoFunction";
import { recordPageView } from "app/utils/APIFunctions";

export const PrivacyPolicyPage = (): React$MixedElement => {
  const websiteName = process.env.REACT_APP_WEBSITE_NAME;
  const suhbeader = useSubheader();
  useMetaTags("Privacy Policy");

  useEffect(() => {
    recordPageView("/privacy");

    suhbeader.setTitle("Privacy Policy");
  }, [suhbeader]);

  return (
    <>
      <Notice svg="/media/svg/icons/Tools/Compass.svg">
        <div>
          Updated: Jan 01, 2022
          <br />
          <br />
          Your privacy is very important to us.
          <br />
          <br />
          This Privacy Policy governs how {websiteName} collects and uses
          information you provide at our website, {websiteName}.com, its
          sub-domains, and any associated web-based and mobile applications
          (collectively, referred as "Services"), as owned and operated by{" "}
          {websiteName}
          It’s{websiteName}’s policy to respect your privacy regarding any
          information we may collect while operating our website and via our
          mobile applications.
        </div>
        <div>
          <br />
          <h2>Outline</h2>
          The following outlines our privacy policy.
          <br />
          <ul>
            <li>
              {" "}
              We only collect personal information when it is absolutely
              required for the operation of service or a feature.
            </li>
            <li>
              We make sure we provide as much as options possible so that you
              are in are in control whether or not and what all and how much
              information you would like to provide.
            </li>
            <li>
              {" "}
              We collect information by fair legal means, and try to provide as
              much as know how up front as how this information is going to be
              used.
            </li>
            <li>
              {" "}
              We use Non Personal Information data points to aggregate and
              understand our users and help provide better services and user
              experience.
            </li>
            <li>
              We only share personal information if required by legal reason
              with law agencies as per the laws.
            </li>
          </ul>
          <div>
            <br />
            <h2>The information we collect</h2>
            When you use our Services, we automatically collect certain computer
            information by the interaction of your mobile phone or web browser
            with our Website. Such information is typically considered Non
            Personal Information. We also collect the following:
            <br />
            <br />
            <ul>
              <li>
                <h3> Cookies</h3>
                Our Website uses "Cookies" to identify the areas of our Website
                that you have visited. A Cookie is a small piece of data stored
                on your computer or mobile device by your web browser. We use
                Cookies to personalize the Content that you see on our Website.
                Most web browsers can be set to disable the use of Cookies.
                However, if you disable Cookies, you may not be able to access
                functionality on our Website correctly or at all.
                <br />
                <br />
              </li>
              <li>
                <h3> Automatic Information</h3>
                We automatically receive information from your web browser or
                mobile device. This information includes the name of the website
                from which you entered our Website, if any, as well as the name
                of the website to which you're headed when you leave our
                website. This information also includes the IP address of your
                computer/proxy server that you use to access the Internet, your
                Internet Website provider name, web browser type, type of mobile
                device, and computer operating system. We use all of this
                information to analyze trends among our Users to help improve
                our Website.
                <br />
                <br />
              </li>

              <li>
                <h3> Website Interactions</h3>
                The Data concerning the User is collected to provide better
                services and user experience in the website, as well as for the
                following purposes: Analytics, Chat, Find Bugs in our website.
                <br />
                <br />
              </li>
              <li>
                <h3> Log Data</h3>
                Like many Website operators, we collect information that your
                browser sends whenever you visit our Website ("Log Data"). This
                Log Data may include information such as your computer's
                Internet Protocol ("IP") address, browser type, browser version,
                the pages of our Website that you visit, the time and date of
                your visit, the time spent on those pages and other statistics.
              </li>
            </ul>
          </div>
          <div>
            <h1>How we use your information</h1>
            We use the information we receive from you as follows:
            <br />
            <br />
            <ul>
              <li>
                <h3>Customizing Our Services</h3>
                We may use the Personally Identifiable information you provide
                to us along with any computer information we receive to
                customize our Services for you.
                <br />
                <br />
              </li>
              <li>
                <h3>
                  Sharing Information with Affiliates and Other Third Parties
                </h3>
                We do not sell, rent, or otherwise provide your Personally
                Identifiable Information to third parties for marketing
                purposes. We may provide your Personally Identifiable
                Information to affiliates that provide services to us with
                regards to our Website (i.e. payment processors, Website hosting
                companies, etc.); such affiliates will only receive information
                necessary to provide the respective services and will be bound
                by confidentiality agreements limiting the use of such
                information.
                <br />
                <br />
              </li>
              <li>
                <h3> Data Aggregation</h3>
                We retain the right to collect and use any Non Personal
                Information collected from your use of our Website and aggregate
                such data for internal analytics that improve our Website and
                Service as well as for use or resale to others. At no time is
                your Personally Identifiable Information included in such data
                aggregations.
                <br />
                <br />
              </li>
              <li>
                <h3>Legally Required Releases of Information</h3>
                We may be legally required to disclose your Personally
                Identifiable Information, if such disclosure is (a) required by
                subpoena, law, or other legal process; (b) necessary to assist
                law enforcement officials or government enforcement agencies;
                (c) necessary to investigate violations of or otherwise enforce
                our Legal Terms; (d) necessary to protect us from legal action
                or claims from third parties including you and/or other Members;
                and/or (e) necessary to protect the legal rights, personal/real
                property, or personal safety of
                {websiteName},our Users, employees, and affiliates.
                <br />
                <br />
              </li>
            </ul>
          </div>
          <div>
            <h3>Opt-Out</h3>
            We offer you the chance to "opt-out" of having your personally
            identifiable information used for particular functions, when we ask
            you for this detail. When you register for the Services, if you do
            not want to receive any additional material or notifications from
            us, you can show your preference on our registration form.
            <br />
            <br />
          </div>
          <div>
            <h3>IP Address & Device Identifier</h3>
            The information we collect also includes the Internet Protocol
            (“IP”) address or other unique device identifier (“Device
            Identifier”) for any device (computer, mobile phone, tablet, etc.)
            used to access the Service. A Device Identifier is a number that is
            automatically assigned or connected to the device you use to access
            the Service, and our servers identify your device by its Device
            Identifier. Some mobile service providers may also provide us or our
            third party service providers with information regarding the
            physical location of the device used to access the Service.
            <br />
            <br />
          </div>
          <div>
            <h3>Links to other websites</h3>
            Our website may contain links to other websites of interest.
            However, once you have used these links to leave our site, you
            should note that we do not have any control over that other website.
            Therefore, we cannot be responsible for the protection and privacy
            of any information which you provide whilst visiting such sites and
            such sites are not governed by this privacy statement. You should
            exercise caution and look at the privacy statement applicable to the
            website in question.
            <br />
            <br />
          </div>
          <div>
            <h3>Social Media Widgets</h3>
            Our Website includes Social Media Features, such as the Facebook
            Like button and Widgets, such as the Share this button. These
            Features may collect your IP address, which page you are visiting on
            our site, and may set a cookie to enable the Feature to function
            properly. Social Media Features and Widgets are either hosted by a
            third party or hosted directly on our Site. Your interactions with
            these Features are governed by the privacy policy of the company
            providing it.
            <br />
            <br />
          </div>
          <div>
            <h3>Advertising</h3>
            We use third-party advertising companies to serve ads when you visit
            our Web site. These companies may use information (not including
            your name, address, email address or telephone number) about your
            visits to this and other Web sites in order to provide
            advertisements about goods and services of interest to you.
            <br />
            <br />
          </div>
          <div>
            <h3>Affilate Links</h3>
            We may offer products info or link to third party vendor as an
            affliiate and earn commisions.
            <br />
            <br />
          </div>
          <div>
            <h3>Mobile Analytics</h3>
            We use mobile analytics software to allow us to better understand
            the functionality of our mobile software on your phone. This
            software may record information such as how often you use the
            application, the events that occur within the application,
            aggregated usage, performance data, and where the application was
            downloaded from. We do not link the information we store within the
            analytics software to any personally identifiable information you
            submit within the mobile app.
            <br />
            <br />
          </div>
          <div>
            <h3>Data Retention</h3>
            We will retain your information for as long as your account is
            active or as needed to provide you services. If you wish to cancel
            your account or request that we no longer use your information to
            provide you services contact us. We will retain and use your
            information as necessary to comply with our legal obligations,
            resolve disputes, and enforce our agreements.
            <br />
            <br />
          </div>
          <div>
            <h3>Security</h3>
            The security of your personal information is important to us. We
            follow generally accepted industry standards to protect the personal
            information submitted to us, both during transmission and once we
            receive it, and our Website and apps are protected by HTTPS
            encryption. No method of transmission over the Internet, or method
            of electronic storage, is 100% secure, however. Therefore, we cannot
            guarantee its absolute security.
            <br />
            <br />
          </div>
          <div>
            <h3>Privacy Policy Changes</h3>
            {websiteName}reserves the right to modify this privacy statement at
            any time, so please review it frequently. If there are any changes
            to this privacy policy, we will replace this page with an updated
            version. It is therefore in your own interest to check the "Privacy
            Policy" page any time you access our web site so as to be aware of
            any changes which may occur from time to time.
            <br />
            <br />
          </div>
          <div>
            <h3>Contact Us</h3>
            If you have questions or wish to send us comments about this Privacy
            Policy, please send an email to support@{websiteName}.com
          </div>
        </div>
      </Notice>
    </>
  );
};
