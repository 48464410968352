// @flow
import ToolGrid from "app/components/ToolGrid";
import React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useSubheader } from "_metronic/layout/index";
import { Notice } from "_metronic/_partials/controls/Notice";
import { ToolType } from "app/utils/Constants";
import { useMetaTags } from "app/utils/SeoFunction";
import { recordPageView } from "app/utils/APIFunctions";
import CompanyGrid from "../components/CompanyGrid";
import CompanyList from "app/components/CompanyList";

export const CompanyHome = (): React$MixedElement => {
  const suhbeader = useSubheader();
  useMetaTags(
    "Investment Companies",
    "Value add tools that help you become aware, understand and take control of your investment."
  );
  useEffect(() => {
    recordPageView("/company/home");
    suhbeader.setTitle("Companies");
  }, [suhbeader]);

  return (
    <>
      <Notice svg="/media/svg/icons/Tools/Compass.svg">
        <h1>Company Analysis</h1>
        Comprehensive company analysis and investment reports.
      </Notice>

      <CompanyGrid></CompanyGrid>
    </>
  );
};
