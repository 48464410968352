import React from "react";
import { Route } from "react-router-dom";
import { Content } from "./Content";
/*
 * Represents a HOF that encapsulates a content with the Route
 * It can wrap : Funciton,
 */
export function ContentRoute({ children, component, render, ...props }) {
  return (
    <Route {...props}>
      { (routeProps) => {

        // childen could be a function
        if (typeof children === "function") {
          return <Content>{children(routeProps)}</Content>;
        }

        // if no routing is defined
        if (!routeProps.match) {
          return null;
        }

        // has children
        if (children) {
          return <Content>{children}</Content>;
        }

        // has a component
        if (component) {
          return (
            <Content>{React.createElement(component, routeProps)}</Content>
          );
        }

        // asked to render
        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
