// @flow
import { runApiQuery } from "app/utils/APIFunctions";
import { gql } from "graphql-request";
import React, { useEffect, useState } from "react";
import { Card, CardColumns } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { Question } from "../utils/Constants";
import { Badge } from "react-bootstrap";
const FRAGMENT_QUESTION_FIELDS = gql`
  fragment QuestionFields on Question {
    __typename
    guid
    title
    descr
    contents
    tags
    state
    time_to_open
    time_to_close
    time_to_judge
    published_at
    seo_guid
    predictions {
      guid
      title
      descr
      contents
      tags
    }
    relatedQuestions {
      title
      guid
      seo_guid
    }
  }
`;

const GET_QUESTION_QUERY = gql`
  query {
    questionQuery {
      ...QuestionFields
    }
  }
  ${FRAGMENT_QUESTION_FIELDS}
`;

const GET_QUESTION_BY_CATEGORY_ID_QUERY = gql`
  query($categoryId: ID!) {
    questionQuery(category_id: $categoryId) {
      ...QuestionFields
    }
  }
  ${FRAGMENT_QUESTION_FIELDS}
`;

const GET_QUESTION_BY_CATEGORY_SEO_GUID_QUERY = gql`
  query($categorySeoGuid: String!) {
    questionQuery(category_seo_guid: $categorySeoGuid) {
      ...QuestionFields
    }
  }
  ${FRAGMENT_QUESTION_FIELDS}
`;
const GET_QUESTION_BY_STATE_QUERY = gql`
  query($state: String!) {
    questionQuery(state: $state) {
      ...QuestionFields
    }
  }
  ${FRAGMENT_QUESTION_FIELDS}
`;

const GET_QUESTION_BY_CATEGORY_ID_AND_STATE_QUERY = gql`
  query($categoryId: ID!, $state: String!) {
    questionQuery(category_id: $categoryId, state: $state) {
      ...QuestionFields
    }
  }
  ${FRAGMENT_QUESTION_FIELDS}
`;

const GET_QUESTION_BY_CATEGORY_SEO_GUID_AND_STATE_QUERY = gql`
  query($categorySeoGuid: String!, $state: String!) {
    questionQuery(category_seo_guid: $categorySeoGuid, state: $state) {
      ...QuestionFields
    }
  }
  ${FRAGMENT_QUESTION_FIELDS}
`;

// take in the props
type Props = {
  categoryId?: number,
  state?: string,
  categorySeoGuid?: string,
  tagSeoGuid?: string,
  orderBy?: string,
};

/**
 * Represents the questions grid
 * @param {} param0
 * @returns
 */
export default function QuestionGrid({
  categoryId,
  categorySeoGuid,
  state,
  tagSeoGuid,
  orderBy,
}: Props): React$MixedElement {
  const [questions, setQuestions] = useState(null);

  console.log("state", state);
  console.log("category", categorySeoGuid);
  useEffect(() => {
    const loadQuestions = async () => {
      let data = null;
      if (categoryId && state) {
        const variables = {
          categoryId: categoryId,
          state: state,
        };
        data = await runApiQuery(
          GET_QUESTION_BY_CATEGORY_ID_AND_STATE_QUERY,
          variables
        );
      } else if (categorySeoGuid && state) {
        const variables = {
          categorySeoGuid: categorySeoGuid,
          state: state,
        };
        data = await runApiQuery(
          GET_QUESTION_BY_CATEGORY_SEO_GUID_AND_STATE_QUERY,
          variables
        );
      } else if (categoryId) {
        const variables = {
          categoryId: categoryId,
        };
        data = await runApiQuery(GET_QUESTION_BY_CATEGORY_ID_QUERY, variables);
      } else if (categorySeoGuid) {
        const variables = {
          categorySeoGuid: categorySeoGuid,
        };
        data = await runApiQuery(
          GET_QUESTION_BY_CATEGORY_SEO_GUID_QUERY,
          variables
        );
      } else if (state) {
        console.log("run state query");
        const variables = {
          state: state,
        };
        data = await runApiQuery(GET_QUESTION_BY_STATE_QUERY, variables);
      } else {
        data = await runApiQuery(GET_QUESTION_QUERY);
      }
      if (data.questionQuery) {
        setQuestions(data.questionQuery);
      }
    };
    loadQuestions();
  }, [categoryId, state, categorySeoGuid, orderBy, tagSeoGuid]);
  return (
    <>
      {questions && (
        <>
          {questions.map((question: Question) => {
            return (
              <>
                <Link
                  to={"/question/" + question.guid + "/" + question.seo_guid}
                  key={question.guid}
                >
                  <Card>
                    {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
                    <Card.Body style={{ padding: 5 }}>
                      <Card.Title
                        className="text-dark"
                        style={{ padding: 20, margin: 0 }}
                      >
                        <i className="flaticon-paper-plane-1 text-danger icon-lg"></i>{" "}
                        {question.title}
                      </Card.Title>
                    </Card.Body>
                    <Card.Footer style={{ padding: 10 }}>
                      <small className="text-muted">
                        {" "}
                        <Badge variant="light">{question.state}</Badge>
                      </small>
                    </Card.Footer>
                  </Card>
                </Link>
                <br></br>
              </>
            );
          })}
        </>
      )}
    </>
  );
}
