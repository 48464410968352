// @flow
import type { Problem } from "../utils/Constants";
import { ProgressBar } from "react-bootstrap";
import ProblemView from "../components/ProblemView";
import RemedyView from "../components/RemedyView";
import { useSubheader } from "_metronic/layout/index";

import { recordPageView, runApiQuery } from "app/utils/APIFunctions";
import { useMetaTags } from "app/utils/SeoFunction";
import { gql } from "graphql-request";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls/Card";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

const ProblemPagBase = (props): React$MixedElement => {
  const problemURL = props.location.pathname;
  console.log("ProblemPage" + problemURL);

  const [problem: Problem, setProblem] = useState(null);
  const [problemGuid: string, setProblemGuid] = useState(
    problemURL.split("/")[2]
  );
  // check if got referred via search
  const query = new URLSearchParams(props.location.search);
  const guidTypeValue = query.get("guid_type");
  console.log("guidType", guidTypeValue);
  const [guidType: string, setGuidType] = useState(guidTypeValue);

  const suhbeader = useSubheader();

  useMetaTags(
    "Garden Remedies",
    "Get relief from common investmenting problems with time tested solutions that simply work and maximise your output."
  );

  const FRAGMENT_PROBLEM_FIELDS = gql`
    fragment ProblemFields on Problem {
      __typename
      guid
      title
      descr
      contents
      tags
      remedies {
        guid
        title
        descr
        contents
        tags
      }
      relatedProblems {
        title
        guid
        seo_guid
      }
    }
  `;

  const GET_PROBLEM_QUERY = gql`
    query($guid: ID!) {
      problemQuery(guid: $guid) {
        ...ProblemFields
      }
    }
    ${FRAGMENT_PROBLEM_FIELDS}
  `;

  const GET_PROBLEM_QUERY_BY_NODE = gql`
    query($guid: ID!) {
      problemQuery(node_guid: $guid) {
        ...ProblemFields
      }
    }
    ${FRAGMENT_PROBLEM_FIELDS}
  `;

  useEffect(() => {
    console.log("in useEffect");
    const loadProblem = async () => {
      if (problem == null) {
        let data = null;
        const variables = {
          guid: problemGuid,
        };
        if (guidType == null) {
          data = await runApiQuery(GET_PROBLEM_QUERY, variables);
        } else {
          data = await runApiQuery(GET_PROBLEM_QUERY_BY_NODE, variables);
        }
        //    console.log(data);
        if (data.problemQuery && data.problemQuery[0]) {
          setProblem(data.problemQuery[0]);
          //console.log(data.problemQuery[0]);
        }
      }
    };
    loadProblem();
    if (problem && suhbeader.title !== problem.title) {
      suhbeader.setTitle(problem.title);

      let breadcrumbs;

      breadcrumbs = [
        {
          title: "Problems",
          pathname: "/problem/home",
        },
      ];
      suhbeader.setBreadcrumbs(breadcrumbs);
    }
    recordPageView(problemURL);
  }, [problemGuid, problemURL, problem, suhbeader, GET_PROBLEM_QUERY, props]);

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          {problem && <ProblemView problem={problem}></ProblemView>}

          {problem &&
            problem.remedies &&
            problem.remedies.map((remedy, index) => {
              remedy.index = index;
              return (
                <RemedyView key={remedy.guid} remedy={remedy}></RemedyView>
              );
            })}
        </div>
        <div className="col-md-3 sticky-top-panel">
          <Card>
            <CardHeader title={"Problem"} toolbar={() => {}}>
              <ProgressBar
                variant={"success"}
                animated
                now={100}
                style={{ height: "5px", width: "100%" }}
              />
            </CardHeader>
            <CardBody>
              <div> Has {problem?.remedies?.length ?? 0} Remedies</div>
              <br />
              <ol>
                {problem?.remedies?.map((remedy, index) => {
                  remedy.index = index;
                  return <li key={remedy.guid}> {remedy.title}</li>;
                })}
              </ol>
            </CardBody>
          </Card>

          <Card className="sticky-top-panel">
            <CardHeader title={"Related Problems"} toolbar={() => {}}>
              <ProgressBar
                variant={"warning"}
                animated
                now={100}
                style={{ height: "5px", width: "100%" }}
              />
            </CardHeader>
            <CardBody>
              <ul>
                {problem?.relatedProblems?.map((problem, index) => {
                  problem.index = index;

                  return (
                    <li key={problem.guid}>
                      {" "}
                      <Link
                        to={`/problem/${problem.guid}/${problem.seo_guid}`}
                        onClick={() => {
                          setProblemGuid(problem.guid);
                          setProblem(null);
                          setGuidType(null);
                        }}
                        key={problem.guid}
                      >
                        {problem.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export const ProblemPage = withRouter(ProblemPagBase);
