import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import * as auth from "../Auth";

function NotificationSettings(props) {
  // Fields
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  //const user = useSelector((state) => state.auth.user, shallowEqual);
  useEffect(() => {}, [user]);
  let user = {
    emailSettings: {
      emailNotification: "values.emailNotification",
      sendCopyToPersonalEmail: "values.sendCopyToPersonalEmail",
    },
  };

  // Methods
  const saveUser = (values, setStatus, setSubmitting) => {
    setLoading(true);

    // user for update preparation
    //dispatch(props.setUser(updatedUser));
    // setTimeout(() => {
    //   setloading(false);
    //   setSubmitting(false);
    //   // Do request to your server for user update, we just imitate user update there, For example:
    //   // update(updatedUser)
    //   //  .then(()) => {
    //   //    setloading(false);
    //   //  })
    //   //  .catch((error) => {
    //   //    setloading(false);
    //   //    setSubmitting(false);
    //   //    setStatus(error);
    //   // });
    // }, 1000);
  };
  // UI Helpers
  const initialValues = {
    emailNotification: true,
    sendCopyToPersonalEmail: false,
  };
  const Schema = Yup.object().shape({
    emailNotification: Yup.bool(),
    sendCopyToPersonalEmail: Yup.bool(),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Notifications Settings
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Change your notification settings
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save
            {formik.isSubmitting}
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          <div className="row"></div>
          <div className="form-group row align-items-center">
            <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">
              Send my feed digest(weekly)
            </label>
            <div className="col-lg-9 col-xl-6">
              <span className="switch switch-sm">
                <label>
                  <input
                    type="checkbox"
                    name="emailNotification"
                    checked={formik.values.emailNotification}
                    onChange={formik.handleChange}
                  />
                  <span></span>
                </label>
              </span>
            </div>
          </div>
          <div className="form-group row align-items-center d-none">
            <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">
              Notify about profile views(bimonthly)
            </label>
            <div className="col-lg-9 col-xl-6">
              <span className="switch switch-sm">
                <label>
                  <input
                    type="checkbox"
                    name="sendCopyToPersonalEmail"
                    checked={formik.values.sendCopyToPersonalEmail}
                    onChange={formik.handleChange}
                  />
                  <span></span>
                </label>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* end::Form */}
    </form>
  );
}

export default connect(null, auth.actions)(NotificationSettings);
