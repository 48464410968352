import { createStore } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
import { applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

// types of action supported
export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
};

// initial state
const initialAuthState = {
  user: undefined,
  authToken: undefined,
  authToken: localStorage.getItem("TOKEN"),
};

/* Reducer function
 **/
export const reducer = persistReducer(
  { storage, key: "remedy-monkey-auth", whitelist: ["authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        console.log("store auth token ", authToken);
        localStorage.setItem("TOKEN", authToken);
        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        localStorage.removeItem("TOKEN");

        return {
          user: undefined,
          authToken: undefined,
        };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

// container for all actions
export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

// redux functions
export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
}

// TODO : I Added it but not sure if this works
// // Create the saga middleware
// const sagaMiddleware = createSagaMiddleware();
// // Mount it on the Store
// const store = createStore(reducer, applyMiddleware(sagaMiddleware));

// // Then run the saga
// sagaMiddleware.run(saga);

const store = createStore(reducer, initialAuthState);
store.subscribe(() => {
  console.log("store subscribe, save auth token");
  localStorage.setItem("TOKEN", store.getState().authToken);
});

export default store;
