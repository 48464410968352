// @flow
import { Notice } from "_metronic/_partials/controls/Notice";
import { useSubheader } from "_metronic/layout/index";

import React from "react";
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useMetaTags } from "app/utils/SeoFunction";
import { runApiQuery } from "app/utils/APIFunctions";
import { gql } from "graphql-request";
import { Card, CardColumns } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { Question } from "../utils/Constants";
import { Badge } from "react-bootstrap";

const FRAGMENT_QUESTION_FIELDS = gql`
  fragment QuestionFields on Question {
    __typename
    guid
    title
    contents
    tags
    state
  }
`;

const GET_MY_PREDICTION_SUBMISSIONS_QUERY = gql`
  query {
    myPredictionSubmissions {
      ...QuestionFields
    }
  }
  ${FRAGMENT_QUESTION_FIELDS}
`;

const GET_MY_PREDICTION_SUBMISSIONS_BY_CATEGORY_SEO_GUID_QUERY = gql`
  query($categorySeoGuid: String!) {
    myPredictionSubmissions(category_seo_guid: $categorySeoGuid) {
      ...QuestionFields
    }
  }
  ${FRAGMENT_QUESTION_FIELDS}
`;
const GET_MY_PREDICTION_SUBMISSIONS_BY_STATE_QUERY = gql`
  query($state: String!) {
    myPredictionSubmissions(state: $state) {
      ...QuestionFields
    }
  }
  ${FRAGMENT_QUESTION_FIELDS}
`;

const GET_MY_PREDICTION_SUBMISSIONS_BY_CATEGORY_SEO_GUID_AND_STATE_QUERY = gql`
  query($categorySeoGuid: String!, $state: String!) {
    myPredictionSubmissions(
      category_seo_guid: $categorySeoGuid
      state: $state
    ) {
      ...QuestionFields
    }
  }
  ${FRAGMENT_QUESTION_FIELDS}
`;

// take in the props
type Props = {
  questionState?: string,
  categorySeoGuid?: string,
  tagSeoGuid?: string,
  orderBy?: string,
};

export const MyQuestions = (): React$MixedElement => {
  const suhbeader = useSubheader();
  useMetaTags("My Questions");
  const [tagSeoGuid, setTagSeoGuid] = useState(null);
  const [categorySeoGuid, setCategorySeoGuid] = useState(null);
  const [questionState, setQuestionState] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [questions, setQuestions] = useState(null);

  useEffect(() => {
    const loadQuestions = async () => {
      let data = null;
      if (categorySeoGuid && questionState) {
        const variables = {
          categorySeoGuid: categorySeoGuid,
          questionState: questionState,
        };
        data = await runApiQuery(
          GET_MY_PREDICTION_SUBMISSIONS_BY_CATEGORY_SEO_GUID_AND_STATE_QUERY,
          variables
        );
      } else if (categorySeoGuid) {
        const variables = {
          categorySeoGuid: categorySeoGuid,
        };
        data = await runApiQuery(
          GET_MY_PREDICTION_SUBMISSIONS_BY_CATEGORY_SEO_GUID_QUERY,
          variables
        );
      } else if (questionState) {
        console.log("run state query");
        const variables = {
          questionState: questionState,
        };
        data = await runApiQuery(
          GET_MY_PREDICTION_SUBMISSIONS_BY_STATE_QUERY,
          variables
        );
      } else {
        data = await runApiQuery(GET_MY_PREDICTION_SUBMISSIONS_QUERY);
      }
      if (data.myPredictionSubmissions) {
        setQuestions(data.myPredictionSubmissions);
      }
    };
    loadQuestions();
  }, [questionState, categorySeoGuid, orderBy, tagSeoGuid]);

  useEffect(() => {
    ReactGA.pageview("/my/questions");
    suhbeader.setTitle("My Questions");
  }, [suhbeader]);

  return (
    <>
      <Notice svg="/media/svg/icons/Tools/Compass.svg">
        <h1>My Questions</h1>
        Question you have asked
      </Notice>
      {questions && (
        <>
          {questions.map((question: Question) => {
            return (
              <>
                <Link
                  to={"/question/" + question.guid + "/" + question.seo_guid}
                  key={question.guid}
                >
                  <Card>
                    {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
                    <Card.Body style={{ padding: 5 }}>
                      <Card.Title
                        className="text-dark"
                        style={{ padding: 20, margin: 0 }}
                      >
                        <i className="flaticon-paper-plane-1 text-danger icon-lg"></i>{" "}
                        {question.title}
                      </Card.Title>
                    </Card.Body>
                    <Card.Footer style={{ padding: 10 }}>
                      <small className="text-muted">
                        {" "}
                        <Badge variant="light">{question.state}</Badge>
                      </small>
                    </Card.Footer>
                  </Card>
                </Link>
                <br></br>
              </>
            );
          })}
        </>
      )}
    </>
  );
};
