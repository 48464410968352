import React, { useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const PlantDiseaseIdentifier = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [possibleDisease, setPossibleDisease] = useState("");

  const questions = [
    {
      question: "Does the plant have spots or lesions on its leaves?",
      options: ["Yes", "No"],
      key: "spotsOnLeaves",
    },
    {
      question: "Are the spots or lesions circular or irregular in shape?",
      options: ["Circular", "Irregular"],
      key: "spotShape",
    },
    {
      question: "Are the leaves turning yellow or showing signs of wilting?",
      options: ["Yes", "No"],
      key: "yellowLeaves",
    },
    {
      question: "Are there any visible pests on the plant?",
      options: ["Yes", "No"],
      key: "visiblePests",
    },
    {
      question: "Are the leaf edges turning brown and crispy?",
      options: ["Yes", "No"],
      key: "brownEdges",
    },
    {
      question:
        "Is the plant showing signs of stunted growth or distorted growth?",
      options: ["Yes", "No"],
      key: "stuntedGrowth",
    },
  ];

  const handleAnswer = (answer) => {
    setAnswers({ ...answers, [questions[currentQuestionIndex].key]: answer });

    if (currentQuestionIndex === questions.length - 1) {
      // Identify the possible disease based on answers
      identifyDisease();
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // Move to the next question
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const identifyDisease = () => {
    // Implement the algorithm to identify the possible disease based on answers
    console.log("indentify disease");

    // Sample rules to match answers and identify a possible disease (for demonstration purposes)
    if (
      answers.spotsOnLeaves === "Yes" &&
      answers.spotShape === "Circular" &&
      answers.yellowLeaves === "No"
    ) {
      setPossibleDisease("Possible disease: Powdery Mildew");
    } else if (
      answers.spotsOnLeaves === "Yes" &&
      answers.spotShape === "Irregular" &&
      answers.visiblePests === "No"
    ) {
      setPossibleDisease("Possible disease: Bacterial Leaf Spot");
    } else if (
      answers.yellowLeaves === "Yes" &&
      answers.brownEdges === "Yes" &&
      answers.stuntedGrowth === "No"
    ) {
      setPossibleDisease("Possible disease: Watering issues");
    } else {
      setPossibleDisease(
        "Could not determine the disease based on the provided information."
      );
    }
  };

  return (
    <Container>
      <h2>Plant Disease Identifier</h2>
      {currentQuestionIndex < questions.length ? (
        <>
          <p>{questions[currentQuestionIndex].question}</p>
          <Row>
            {questions[currentQuestionIndex].options.map((option) => (
              <Col key={option} xs={6} md={3}>
                <Button
                  variant="primary"
                  block
                  onClick={() => handleAnswer(option)}
                >
                  {option}
                </Button>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <p>{possibleDisease}</p>
      )}
    </Container>
  );
};

export default PlantDiseaseIdentifier;
