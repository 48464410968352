import axios from "axios";
import { recordPageView, runApiQuery } from "app/utils/APIFunctions";
import { gql } from "graphql-request";

export const CUSTOMERS_URL = "api/company";

// CREATE =>  POST: add a new customer to the server
export function createCompany(customer) {
  return axios.post(CUSTOMERS_URL, { customer });
}

// READ
export function getAllCompanies() {
  console.log("get all customer");
  return axios.get(CUSTOMERS_URL);
}

export function getCompanyById(customerId) {
  return axios.get(`${CUSTOMERS_URL}/${customerId}`);
}

const FRAGMENT_COMPANY_FIELDS = gql`
  fragment CompanyFields on Company {
    __typename
    guid
    title
    photo
    seo_guid
    profile {
      sector
      industry
      symbol
      is_in_sp500
      market_cap
      market_cap_class
    }
    alert {
      contents
      sentiment_type
      buy_rating
      published_at
    }
    score {
      business_score
      management_score
      financial_score
      growth_score
      profitability_score
      analysis_score
      valuation_score
      external_score
      score
      rank
      buy_rating
      last_refresh_at
    }
  }
`;

const GET_COMPANY_QUERY = gql`
  query($mode: String!) {
    companyQuery(mode: $mode) {
      ...CompanyFields
    }
  }
  ${FRAGMENT_COMPANY_FIELDS}
`;

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCompanies(queryParams) {
  console.log("find companies");
  //return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
  const variables = { mode: JSON.stringify(queryParams) };
  return runApiQuery(GET_COMPANY_QUERY, variables);
}

// UPDATE => PUT: update the customer on the server
export function updateCompany(customer) {
  return axios.put(`${CUSTOMERS_URL}/${customer.id}`, { customer });
}

// UPDATE Status
export function updateStatusForCompanies(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForCompany`, {
    ids,
    status,
  });
}

// DELETE => delete the customer from the server
export function deleteCompany(id) {
  return axios.delete(`${CUSTOMERS_URL}/${id}`);
}

// DELETE Companies by ids
export function deleteCompanies(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteCompanies`, { ids });
}
