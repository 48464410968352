import { MarketCapClass, RatingGrade, RatingScale } from "app/utils/Constants";
import { Badge, Table } from "react-bootstrap";
import React from "react";

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function(word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
}

export function formatAmountCompact(value) {
  var suffixes = ["", "K", "M", "B", "T"];
  var suffixNum = Math.floor(("" + value).length / 3);
  var shortValue = parseFloat(
    (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  );
  if (shortValue % 1 != 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
}

export function formatAmount(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function mapCompanyMarketCapEnumToLabel(value) {
  const labelMap = {
    1: "Small-Cap",
    2: "Mid-Cap",
    3: "Large-Cap",
    4: "Micro-Cap",
    5: "Mega-Cap",
    6: "Nano-Cap",
  };

  // Check if the value exists in the label map
  if (value in labelMap) {
    return labelMap[value];
  } else {
    return "Unknown"; // Return a default label if the value is not found
  }
}

export function mapCompanyGrowthRateEnumToLabel(growthRate) {
  const labels = {
    1: "Very Low Growth",
    2: "Low Growth",
    3: "Moderate Growth",
    4: "High Growth",
    5: "Very High Growth",
  };

  return labels[growthRate] || "Unknown";
}

export function mapDividendYieldEnumToLabel(dividendYieldCategory) {
  const DividendYieldEnum = {
    1: "No Dividend",
    2: "Low Dividend Yield",
    3: "Moderate Dividend Yield",
    4: "High Dividend Yield",
    5: "Very High Dividend Yield",
  };

  return DividendYieldEnum[dividendYieldCategory] || "Unknown";
}

export function mapAnalysisSectionEnumToLabel(enumValue) {
  const EnumLabels = {
    0: "Overview",
    1: "Product and Services",
    2: "Product Revenue",
    3: "Strengths",
    4: "Weakness",
    5: "Opportunities",
    6: "Threats",
    7: "Positive Catalysts",
    8: "Negative Catalysts",
  };

  return EnumLabels[enumValue] || "Unknown";
}

export function reduceHeadingLevels(htmlString, level) {
  // Create a temporary div element to parse the HTML string
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  // Find all heading elements (h1, h2, h3, etc.) in the div
  const headings = tempDiv.querySelectorAll("h1, h2, h3, h4, h5, h6");

  // Iterate through the heading elements and increase their level
  headings.forEach((heading) => {
    const currentLevel = parseInt(heading.tagName[1]); // Extract the current heading level (e.g., h1 to 1)
    if (currentLevel < 6) {
      const newLevel = currentLevel + level;
      heading.outerHTML = `<h${newLevel}>${heading.innerHTML}</h${newLevel}>`;
    }
  });

  // Get the modified HTML content
  const modifiedHTML = tempDiv.innerHTML;
  return modifiedHTML;
}

export function mapRatingToGradeBadge(rating, scale: RatingScale) {
  console.log("Rating", rating);
  let grade = mapRatingToGrade(rating, scale);
  console.log("Grade ", grade);
  let color;
  if (
    grade == RatingGrade.A ||
    grade == RatingGrade.A_P ||
    grade == RatingGrade.A_M
  ) {
    color = "primary";
  } else if (
    grade == RatingGrade.B ||
    grade == RatingGrade.B_P ||
    grade == RatingGrade.B_M
  ) {
    color = "success";
  } else if (
    grade == RatingGrade.C ||
    grade == RatingGrade.C_P ||
    grade == RatingGrade.C_M
  ) {
    color = "info";
  } else if (
    grade == RatingGrade.D ||
    grade == RatingGrade.D_P ||
    grade == RatingGrade.D_M
  ) {
    color = "warning";
  } else if (grade == RatingGrade.F) {
    color = "danger";
  } else {
    return <Badge variant="light">x</Badge>;
  }
  return <Badge variant={color}>{grade}</Badge>;
}

export function mapRatingToGrade(rating, scale) {
  let score = rating * 1;
  if (scale == RatingScale.P5) {
    score = rating * 2;
  } else if (scale == RatingScale.P10) {
    score = rating;
  } else if (scale == RatingScale.P100) {
    score = rating / 10;
  } else if (scale == RatingScale.P3) {
    score = rating * 3.33;
  }

  if (score <= 0) {
    return RatingGrade.U;
  }
  if (score >= 9) {
    return RatingGrade.A_P;
  } else if (score >= 8.5) {
    return RatingGrade.A;
  } else if (score >= 8) {
    return RatingGrade.A_M;
  } else if (score >= 7.5) {
    return RatingGrade.B_P;
  } else if (score >= 7.0) {
    return RatingGrade.B;
  } else if (score >= 6.5) {
    return RatingGrade.B_M;
  } else if (score >= 6) {
    return RatingGrade.C_P;
  } else if (score >= 5.5) {
    return RatingGrade.C;
  } else if (score >= 5) {
    return RatingGrade.C_M;
  } else if (score >= 4.5) {
    return RatingGrade.D_P;
  } else if (score >= 4) {
    return RatingGrade.D;
  } else if (score >= 3.5) {
    return RatingGrade.D_M;
  } else {
    return RatingGrade.F;
  }
}

export function mapBuyRatingEnumToLabel(rating) {
  const labels = {
    5: "Strong Buy",
    4: "Buy",
    3: "Hold",
    2: "Sell",
    1: "Strong Sell",
  };

  return labels[rating] || "Unknown";
}

export function mapBuyRatingToGradeBadge(rating) {
  let label = mapBuyRatingEnumToLabel(rating);

  let color;
  if (rating == 1) {
    color = "danger";
  } else if (rating == 2) {
    color = "warning";
  } else if (rating == 3) {
    color = "info";
  } else if (rating == 4) {
    color = "success";
  } else if (rating == 5) {
    color = "primary";
  } else {
    return <Badge variant="light">Unknown</Badge>;
  }
  return <Badge variant={color}>{label}</Badge>;
}

export function mapMarketCapClassToBadge(value) {
  let color;
  let name;
  if (value == MarketCapClass.NANO_CAP) {
    color = "primary";
    name = "Nano";
  } else if (value == MarketCapClass.MICRO_CAP) {
    name = "Micro";
    color = "success";
  } else if (value == MarketCapClass.SMALL_CAP) {
    color = "info";
    name = "Small";
  } else if (value == MarketCapClass.MID_CAP) {
    color = "warning";
    name = "Mid";
  } else if (value == MarketCapClass.LARGE_CAP) {
    color = "danger";
    name = "Large";
  } else {
    color = "light";
    name = "Mega";
  }
  return <Badge variant={color}>{name}</Badge>;
}

export function timesAgo(dt) {
  var myDate = new Date(dt + " UTC");
  var ms = myDate.getTime();

  console.log("refresh at ", myDate);

  var now = new Date();
  ms = now - myDate;

  console.log("now ", now);
  var ago = Math.floor(ms / 1000);
  var part = 0;

  if (ago < 2) {
    return "a moment ago";
  }
  if (ago < 5) {
    return "moments ago";
  }
  if (ago < 60) {
    return ago + " seconds ago";
  }

  if (ago < 120) {
    return "a minute ago";
  }
  if (ago < 3600) {
    while (ago >= 60) {
      ago -= 60;
      part += 1;
    }
    return part + " minutes ago";
  }

  if (ago < 7200) {
    return "an hour ago";
  }
  if (ago < 86400) {
    while (ago >= 3600) {
      ago -= 3600;
      part += 1;
    }
    return part + " hours ago";
  }

  if (ago < 172800) {
    return "a day ago";
  }
  if (ago < 604800) {
    while (ago >= 172800) {
      ago -= 172800;
      part += 1;
    }
    return part + " days ago";
  }

  if (ago < 1209600) {
    return "a week ago";
  }
  if (ago < 2592000) {
    while (ago >= 604800) {
      ago -= 604800;
      part += 1;
    }
    return part + " weeks ago";
  }

  if (ago < 5184000) {
    return "a month ago";
  }
  if (ago < 31536000) {
    while (ago >= 2592000) {
      ago -= 2592000;
      part += 1;
    }
    return part + " months ago";
  }

  if (ago < 1419120000) {
    // 45 years, approximately the epoch
    return "more than year ago";
  }

  // TODO pass in Date.now() and ms to check for 0 as never
  return "never";
}
export function timesAgoOld(datetime, full = false) {
  if (!datetime) {
    return "";
  }

  const now = new Date();
  const ago = new Date(datetime);
  let diff = new Date(now - ago);

  const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
  diff -= weeks * (1000 * 60 * 60 * 24 * 7);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  console.log("Diff", days);
  diff -= days * (1000 * 60 * 60 * 24);

  const hours = Math.floor(diff / (1000 * 60 * 60));
  diff -= hours * (1000 * 60 * 60);
  const minutes = Math.floor(diff / (1000 * 60));
  diff -= minutes * (1000 * 60);
  const seconds = Math.floor(diff / 1000);

  const timeUnits = [
    { unit: "year", value: weeks / 52 },
    { unit: "month", value: weeks / 4 },
    { unit: "week", value: weeks },
    { unit: "day", value: days },
    { unit: "hour", value: hours },
    { unit: "minute", value: minutes },
    { unit: "second", value: seconds },
  ];

  const timeAgoStrings = [];

  for (const unitInfo of timeUnits) {
    const { unit, value } = unitInfo;
    if (value >= 1) {
      timeAgoStrings.push(
        `${Math.floor(value)} ${unit}${Math.floor(value) > 1 ? "s" : ""}`
      );
    }
  }

  if (!full) {
    timeAgoStrings.length = 1;
  }

  return timeAgoStrings.length
    ? timeAgoStrings.join(", ") + " ago"
    : "just now";
}

export function getDatePart(datetime) {
  const date = new Date(datetime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
