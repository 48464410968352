// @flow
import { Notice } from "_metronic/_partials/controls/Notice";
import { useSubheader } from "_metronic/layout/index";

import React from "react";
import ReactGA from "react-ga";
import { useEffect } from "react";
import { useMetaTags } from "app/utils/SeoFunction";
import { Link } from "react-router-dom";
import { recordPageView } from "app/utils/APIFunctions";

export const AboutUsPage = (): React$MixedElement => {
  const suhbeader = useSubheader();
  useMetaTags("About Us");
  const websiteName = process.env.REACT_APP_WEBSITE_NAME;

  useEffect(() => {
    recordPageView("/about");
    suhbeader.setTitle("About Us");
  }, [suhbeader]);

  return (
    <>
      <Notice svg="/media/svg/icons/Tools/Compass.svg">
        <h1>About</h1>
        {websiteName} provides essential know-how to get started in investing.
        <br />
        <br />
        We provide a number tools, calculator and tackers to help our friends,
        making investment simple process and long term rewarding activity.
        <br />
      </Notice>
      <Notice className="primary">
        <h1>Our Motto</h1>
        <p>
          “Helping you with your investment needs all step of the way.” –
          InvestorBite
        </p>
        <p>{/*<Button variant="primary">Learn more</Button>*/}</p>
      </Notice>

      {false && (
        <div className="col-md-4">
          <div className={`card card-custom`}>
            {/* Header */}
            <div className="card-header align-items-center border-0 mt-4">
              <h3 className="card-title align-items-start flex-column">
                <span className="font-weight-bolder text-dark">Timeline</span>
                <span className="text-muted mt-3 font-weight-bold font-size-sm">
                  Kickstart Jan 1, 2022
                </span>
              </h3>
            </div>
            {/* Body */}
            <div className="card-body pt-4">
              <div className="timeline timeline-6 mt-3">
                <div className="timeline-item align-items-start">
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                    Jan 10
                  </div>

                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-warning icon-xl"></i>
                  </div>

                  <div className="timeline-content font-weight-bolder text-dark-75 pl-3 font-size-lg">
                    <Link to="/tool/fodmap">Fodmap Tool</Link> - goes live
                  </div>
                </div>

                <div className="timeline-item align-items-start">
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                    Jan 13
                  </div>

                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-success icon-xl"></i>
                  </div>

                  <div className="timeline-content font-weight-bolder text-dark-75 pl-3 font-size-lg">
                    <Link to="/tool/acid-reflux">Acid Reflux Tool</Link> -
                    launched
                  </div>
                </div>

                <div className="timeline-item align-items-start">
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                    Jan 22
                  </div>

                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-danger icon-xl"></i>
                  </div>

                  <div className="timeline-content font-weight-bolder text-dark-75 pl-3 font-size-lg">
                    <Link to="/tool/weight-gain-calculator">
                      {" "}
                      Weight Gain Calculator
                    </Link>{" "}
                    - goes live
                  </div>
                </div>

                <div className="timeline-item align-items-start">
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                    Jan 27
                  </div>

                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-primary icon-xl"></i>
                  </div>
                  <div className="timeline-content font-weight-bolder text-dark-75 pl-3 font-size-lg">
                    <Link to="/tool/weight-loss-calculator">
                      {" "}
                      Weight Loss Calculator
                    </Link>{" "}
                    - released
                  </div>
                </div>
                <div className="timeline-item align-items-start">
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                    Jan 28
                  </div>

                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-danger icon-xl"></i>
                  </div>

                  <div className="timeline-content font-weight-bolder text-dark-75 pl-3 font-size-lg">
                    <Link to="/tool/healthy-weight-calculator">
                      {" "}
                      Healthy Weight Calculator
                    </Link>{" "}
                    - released
                  </div>
                </div>

                <div className="timeline-item align-items-start">
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                    Jan 30
                  </div>

                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-info icon-xl"></i>
                  </div>

                  <div className="timeline-content font-weight-bolder text-dark-75 pl-3 font-size-lg">
                    <Link to="/tool/bmi-calculator"> BMI Calculator</Link> -
                    goes live
                  </div>
                </div>

                <div className="timeline-item align-items-start">
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                    Jan 30
                  </div>

                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-primary icon-xl"></i>
                  </div>

                  <div className="timeline-content font-weight-bolder text-dark-75 pl-3 font-size-lg">
                    <Link to="/tool/bmr-calculator"> BMR Calculator</Link> -
                    goes live
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
