// @flow
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useIsUserLoggedIn } from "app/modules/Auth/AuthService";

/**
 * Reepresets any protected route
 * @param {*} param0
 * @returns
 */
const ProtectedRoute = ({ component: Component, ...props }) => {
  const isUserLoggedIn = useIsUserLoggedIn();
  return (
    <Route
      {...props}
      render={(props) => {
        if (isUserLoggedIn) {
          return <Component />;
        } else {
          // redirect
          return (
            <Redirect
              to={{ pathname: "/auth/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
