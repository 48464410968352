// @flow

export const WeightChangePlanRateTypeEnum = Object.freeze({
  normal: {
    descr: "Normal",
    badge: "success",
    value: "normal",
    weightRange: {
      kg: {
        from: 0,
        to: 0.5,
        descr: "upto to 0.5 kg/week",
      },
      lb: {
        from: 0,
        to: 1.0,
        descr: "0 to 1 lb/week",
      },
    },
  },
  healthy: {
    descr: "Healthy",
    badge: "primary",
    value: "healthy",
    weightRange: {
      kg: {
        from: 0.51,
        to: 0.8,
        descr: "upto to 0.8 kg/week",
      },
      lb: {
        from: 1.01,
        to: 1.7,
        descr: "upto to 1.7 lb/week",
      },
    },
  },
  aggresive: {
    descr: "Aggressive",
    badge: "warning",
    value: "agressive",
    weightRange: {
      kg: {
        from: 0.81,
        to: 1,
        descr: "upto to 1 kg/week",
      },
      lb: {
        from: 1.71,
        to: 2.2,
        descr: "up to 2.2 lb/week",
      },
    },
  },
  unhealthy: {
    descr: "Unhealthy",
    value: "unhealthy",
    badge: "danger",
    weightRange: {
      kg: {
        from: 1.01,
        to: 100,
        descr: "above 1 kg/week",
      },
      lb: {
        from: 2.21,
        to: 220,
        descr: "above to 2.2 lb/week",
      },
    },
  },
});

export const SexEnum = Object.freeze({
  male: "male",
  female: "female",
});

export const ActivityTypeEnum = Object.freeze({
  Sedentry: {
    descr: "Desk Job, little no exercise",
    value: "Sedentry",
    bmrMultiplier: 1.2,
    badge: "success",
  },
  Light: {
    descr: "Light Exercise, 1-3 days/week",
    value: "Light",
    bmrMultiplier: 1.4,
    badge: "primary",
  },
  Moderate: {
    descr: "Moderate Exercise, 3-5 days/week",
    value: "Moderate",
    bmrMultiplier: 1.6,
    badge: "info",
  },
  Heavy: {
    descr: "Heavy Exercise, 6-7 days/week",
    value: "Heavy",
    bmrMultiplier: 1.8,
    badge: "warning",
  },
  Hard: {
    descr: "Physical Job, hard daily Exercise",
    value: "Hard",
    bmrMultiplier: 1.95,
    badge: "danger",
  },
});

export const CalorieCounterMethodEnum = Object.freeze({
  MIFFLIN_ST_JEOR: "MIFFLIN_ST_JEOR",
  HARRIS_BENEDICT: "HARRIS_BENEDICT",
  KATCH_MCARDLE: "KATCH_MCARDLE",
});

// Robinson
// Male:	52 kg + 1.9 kg per inch over 5 feet
//Female:	49 kg + 1.7 kg per inch over 5 feet

// Hamwi
// Male:	48.0 kg + 2.7 kg per inch over 5 feet
// Female:	45.5 kg + 2.2 kg per inch over 5 feet

// Devine
//Male:	50.0 kg + 2.3 kg per inch over 5 feet
//Female:	45.5 kg + 2.3 kg per inch over 5 feet

// Miller
//Male:	56.2 kg + 1.41 kg per inch over 5 feet
//Female:	53.1 kg + 1.36 kg per inch over 5 feet

export const HealthyWeightComputeAlgoEnum = Object.freeze({
  robinson: {
    id: 1,
    name: "Robinson",
    value: "robinson",
    formula: {
      male: {
        minWeightKg: 52,
        heightFactorInch: 1.9,
      },
      female: {
        minWeightKg: 49,
        heightFactorInch: 1.7,
      },
    },
  },
  miller: {
    id: 2,
    name: "Miller",
    value: "miller",
    formula: {
      male: {
        minWeightKg: 56.2,
        heightFactorInch: 1.41,
      },
      female: {
        minWeightKg: 53.1,
        heightFactorInch: 1.36,
      },
    },
  },
  devine: {
    id: 3,
    name: "Devine",
    value: "devine",
    formula: {
      male: {
        minWeightKg: 50,
        heightFactorInch: 2.3,
      },
      female: {
        minWeightKg: 45.5,
        heightFactorInch: 2.3,
      },
    },
  },
  hamwi: {
    id: 4,
    name: "Hamwi",
    value: "hamwi",
    formula: {
      male: {
        minWeightKg: 48,
        heightFactorInch: 2.7,
      },
      female: {
        minWeightKg: 45.5,
        heightFactorInch: 2.2,
      },
    },
  },
});

// bmi category enum
export const BMIRangeEnum = Object.freeze({
  severeThiness: {
    category: "Underweight",
    name: "Severe Thiness",
    badge: "dark",
    value: "severeThiness",
    risk: "Malnutrition Risk",
    bmiRange: {
      from: 0,
      to: 16,
    },
  },
  moderateThiness: {
    name: "Moderate Thiness",
    category: "Underweight",
    badge: "dark",
    value: "moderateThiness",
    risk: "Malnutrition Risk",
    bmiRange: {
      from: 16,
      to: 17,
    },
  },
  mildThiness: {
    name: "Mild Thiness",
    category: "Underweight",
    badge: "dark",
    value: "mildThiness",
    risk: "Malnutrition Risk",
    bmiRange: {
      from: 17,
      to: 18.5,
    },
  },
  normal: {
    name: "Normal",
    category: "Normal",
    badge: "success",
    value: "normal",
    risk: "Low Risk",
    bmiRange: {
      from: 18.5,
      to: 25,
    },
  },
  overweight: {
    name: "Overweight",
    category: "Overweight",
    badge: "warning",
    value: "overweight",
    risk: "Enhanced Risk",
    bmiRange: {
      from: 25.0,
      to: 30,
    },
  },
  obeseI: {
    name: "Obese I",
    category: "Moderately Obese",
    badge: "danger",
    value: "obeseI",
    risk: "Medium Risk",
    bmiRange: {
      from: 30,
      to: 35,
    },
  },
  obeseII: {
    name: "Obese II",
    category: "Severely Obese",
    badge: "danger",
    value: "obeseII",
    risk: "High Risk",
    bmiRange: {
      from: 35,
      to: 40,
    },
  },
  obeseIII: {
    name: "Obese III",
    category: "Very Severely Obese",
    badge: "danger",
    value: "obeseIII",
    risk: "Very High Risk",
    bmiRange: {
      from: 40,
      to: 100,
    },
  },
});

export const HealthyWeightInPoundsVsHeightInInches = Object.freeze({
  minHeight: 58,
  maxHeight: 76,
  data: {
    "58": {
      from: 91,
      to: 115,
    },
    "59": {
      from: 94,
      to: 119,
    },
    "60": {
      from: 97,
      to: 123,
    },
    "61": {
      from: 100,
      to: 127,
    },
    "62": {
      from: 104,
      to: 131,
    },
    "63": {
      from: 107,
      to: 135,
    },
    "64": {
      from: 110,
      to: 140,
    },
    "65": {
      from: 114,
      to: 144,
    },
    "66": {
      from: 118,
      to: 148,
    },
    "67": {
      from: 121,
      to: 153,
    },
    "68": {
      from: 125,
      to: 158,
    },
    "69": {
      from: 128,
      to: 162,
    },
    "70": {
      from: 132,
      to: 167,
    },
    "71": {
      from: 136,
      to: 172,
    },
    "72": {
      from: 140,
      to: 177,
    },
    "73": {
      from: 144,
      to: 182,
    },
    "74": {
      from: 148,
      to: 186,
    },
    "75": {
      from: 152,
      to: 192,
    },
    "76": {
      from: 156,
      to: 197,
    },
  },
});

// @flow
export const QuestionStateEnum = Object.freeze({
  upcoming: {
    descr: "Upcoming Question",
    value: "upcoming",
  },
  open: {
    descr: "Open Question",
    value: "open",
  },
  closed: {
    descr: "Closed Question",
    value: "closed",
  },
  judgement: {
    descr: "Judgement Question",
    value: "judgement",
  },
  resolved: {
    descr: "Resolved Question",
    value: "resolved",
  },
  cancelled: {
    descr: "Cancelled Question",
    value: "cancelled",
  },
});

export const ThemeColorsEnum = Object.freeze({
  "0": "white",
  "1": "primary",
  "2": "secondary",
  "3": "success",
  "4": "info",
  "5": "warning",
  "6": "danger",
  "7": "light",
  "8": "dark",
});

export type QuestionState = $Keys<typeof QuestionStateEnum>;

export type ActivityType = $Keys<typeof ActivityTypeEnum>;
export type SexType = $Keys<typeof SexEnum>;
export type CalorieCounterMethodType = $Keys<typeof CalorieCounterMethodEnum>;
export type WeightChangePlanRateType = $Keys<
  typeof WeightChangePlanRateTypeEnum
>;
export type HealthyWeightComputeAlgoType = $Keys<
  typeof HealthyWeightComputeAlgoEnum
>;
export type BMIRangeValueType = $Values<typeof BMIRangeEnum>;
export type BMIRangeType = $Keys<typeof BMIRangeEnum>;
