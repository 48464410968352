// @flow

import type { Tool } from "app/utils/Constants";
import type { DistanceType } from "app/utils/Measurement";
import type { SexType } from "app/utils/ValueTypes";

import { computeHealthyWeightRangeByVariousAlgos } from "../../utils/LibFunctions";
import { fromCmToInch } from "../../utils/Measurement";
import {
  HealthyWeightComputeAlgoEnum,
  HealthyWeightInPoundsVsHeightInInches,
} from "../../utils/ValueTypes";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls/Card";

import { computeHealthyWeight } from "app/utils/LibFunctions";
import {
  Unit,
  UnitDefinition,
  fromKgToLb,
  fromLbToKg,
  ConversionFormat,
  formatValue,
  fromFeetToInch,
} from "app/utils/Measurement";
import { useMetaTags } from "app/utils/SeoFunction";
import { SexEnum } from "app/utils/ValueTypes";
import React, { useState, useRef, useEffect } from "react";
import { Badge, Col, Form, ProgressBar, Row, Table } from "react-bootstrap";

type Results = {
  healthyWeight: {
    kg: number,
    lb: number,
  },
  healthyWeightRangeLabel: {
    kg: string,
    lb: string,
  },
  isBMIDataAvailable: boolean,
  healthyBMIRangeLabel?: { kg: string, lb: string },
};

type Input = {
  sex: SexType,
  age: number,
  height: {
    unit: DistanceType,
    value1: number,
    value2: number,
  },
};

type Errors = {
  sex?: string,
  height?: string,
  age?: string,
};

// returns the default state based on the mode of the tool
const getDefaultInputState = (): Input => {
  return {
    sex: SexEnum.male,
    height: {
      unit: Unit.Distance.FEET,
      value1: 5,
      value2: 10,
    },
    age: 30,
  };
};

export type Props = {
  tool: Tool,
  toolbar: any,
};

// Tool to calculate weight gain
export default function HealthyWeightCalculatorTool({ tool, toolbar }: Props) {
  const [validated, setValidated] = useState(false);
  const [results: Results, setResults] = useState(null);
  const [errors: Errors, setErrors] = useState({});
  const [input: Input, setInput] = useState(getDefaultInputState());
  const formRef = useRef();
  const [progress: number, setProgress] = useState(20);
  useMetaTags(tool.title, tool.descr);
  // apply changes after when the view is rendered
  useEffect(() => {
    console.log("in : useEffect");
    const newErrors = validateFormInput();
    let totalErrors = Object.keys(newErrors).length;
    console.log("new errors " + totalErrors);
    let newProgress = 100 - totalErrors * 15;
    setProgress(newProgress);
    if (totalErrors > 0) {
      setResults(null);
      setErrors(newErrors);
    } else {
      computeResultsOnSuccess();
    }
  }, [input]);

  // validate all the form
  const validateFormInput = () => {
    console.log("in : validateFormInput");
    const { height, age }: Input = input;
    const newErrors = {};
    // height errors

    if (height.unit === Unit.Distance.FEET) {
      if (!height.value1) newErrors.height = "Enter your height.";
      else if (height.value1 <= 0)
        newErrors.height = "Value in feet must be > 0.";
      else if (!height.value2) newErrors.heightSec = "Enter value in inches";
      else if (height.value2 < 0)
        newErrors.heightSec = "Value in inches must be >= 0.";
      else if (height.value2 >= 12)
        newErrors.heightSec = "Value in inches must be <=11.";
    } else {
      if (!height.value1) newErrors.height = "Enter your height.";
      else if (height.value1 <= 0) newErrors.height = "Height must be > 0.";
    }

    if (age == null) newErrors.age = "Enter your age.";
    else if (parseInt(age) <= 13) newErrors.age = "Age must be > 13.";
    else if (parseInt(age) > 80) newErrors.age = "Age cannot be >= 80.";

    console.log(newErrors);
    return newErrors;
  };

  const handleReset = (event) => {
    console.log("Reset the form");
    // $FlowFixMe
    formRef.current.reset();
    setInput(getDefaultInputState());
    setErrors({});
    setResults(null);
    setProgress(100);
    setValidated(false);
  };

  const computeResultsOnSuccess = () => {
    console.log("in : computeResultsOnSuccess");
    // No errors!
    // Put any logic here for the form submission!
    setValidated(true);
    setProgress(100);
    setErrors({});
    // compute Result
    computeResults();
  };

  // compute results
  const computeResults = () => {
    const { sex, height } = input;

    let weightKg: number = 0;
    let heightInInches: number = 0;

    // handle height
    if (height.unit === Unit.Distance.FEET) {
      heightInInches = fromFeetToInch(height.value1) + parseInt(height.value2);
    } else if (height.unit === Unit.Distance.CENTIMETER) {
      heightInInches = fromCmToInch(height.value1);
    } else {
      console.log(
        "Something went wrong, height units is not known" + height.unit
      );
    }

    heightInInches = formatValue(heightInInches, ConversionFormat.Truncate);
    console.log("Height : " + heightInInches);
    weightKg = computeHealthyWeight(
      HealthyWeightComputeAlgoEnum.robinson.value,
      sex,
      heightInInches
    );
    console.log("Weight in kg", weightKg);
    let weightlb = fromKgToLb(weightKg, ConversionFormat.Round1Digit);
    console.log("Weight in lb", weightlb);

    let weightRangeKg: Array<number> = computeHealthyWeightRangeByVariousAlgos(
      sex,
      heightInInches
    );

    let results: Results = {
      healthyWeight: {
        lb: weightlb,
        kg: formatValue(weightKg, ConversionFormat.Round1Digit),
      },
      healthyWeightRangeLabel: {
        lb: `${fromKgToLb(
          weightRangeKg[0],
          ConversionFormat.Round1Digit
        )} - ${fromKgToLb(weightRangeKg[1], ConversionFormat.Round1Digit)}`,
        kg: `${formatValue(
          weightRangeKg[0],
          ConversionFormat.Round1Digit
        )} - ${formatValue(weightRangeKg[1], ConversionFormat.Round1Digit)}`,
      },
      isBMIDataAvailable: false,
    };

    // check if the height is in the BMI height range data
    let isBMIDataAvailable =
      heightInInches >= HealthyWeightInPoundsVsHeightInInches.minHeight &&
      heightInInches <= HealthyWeightInPoundsVsHeightInInches.maxHeight;

    console.log("H in incheS", heightInInches, isBMIDataAvailable);
    if (isBMIDataAvailable) {
      let bmiWeightRangeInLb: Array<number> = [
        HealthyWeightInPoundsVsHeightInInches["data"][heightInInches].from,
        HealthyWeightInPoundsVsHeightInInches["data"][heightInInches].to,
      ];
      results = {
        ...results,
        isBMIDataAvailable: true,
        healthyBMIRangeLabel: {
          kg: `${fromLbToKg(
            bmiWeightRangeInLb[0],
            ConversionFormat.Round1Digit
          )} - ${fromLbToKg(
            bmiWeightRangeInLb[1],
            ConversionFormat.Round1Digit
          )}`,
          lb: `${bmiWeightRangeInLb[0]} - ${bmiWeightRangeInLb[1]}`,
        },
      };
    }
    setResults(results);
  };

  // set a given field
  const setField = (field, value: any) => {
    console.log(`set ${field} with value `);
    switch (field) {
      case "sex":
        setInput({ ...input, sex: value });
        break;
      case "height":
        setInput({ ...input, height: value });
        break;
      case "heightSec":
        setInput({ ...input, height: value });
        break;
      case "age":
        setInput({ ...input, age: value });
        break;
      default:
        console.log(`Unknow field ${field} in setField`);
        return;
    }

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) {
      console.log(`Remove ${field} from errors list`);
      delete errors[field];
    }
  };

  const toolbarX = () => (
    <>
      <span
        onClick={(e) => handleReset(e)}
        style={{ marginTop: "15px", cursor: "pointer" }}
      >
        <i className="fa fa-undo icon"></i>
      </span>
    </>
  );

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Card className="sticky-top-panel">
            <CardHeader title={"Personal Details"} toolbar={toolbarX()}>
              <ProgressBar
                variant={"success"}
                animated
                now={progress}
                style={{ height: "5px", width: "100%" }}
              />
            </CardHeader>
            <CardBody>
              <Form
                noValidate
                validated={validated}
                // $FlowFixMe
                ref={formRef}
              >
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Sex
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      as="select"
                      required
                      onChange={(e) => setField("sex", e.target.value)}
                    >
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="age">
                  <Form.Label column sm={4}>
                    Age
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="number"
                      placeholder=""
                      required
                      value={input.age}
                      onChange={(e) => setField("age", e.target.value)}
                      isInvalid={errors.age ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.age}
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={4}>
                    <Form.Control as="select" required>
                      <option value={Unit.Duration.YEAR}>
                        {UnitDefinition.Duration.year.plural.toLowerCase()}
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="height">
                  <Form.Label column sm={4}>
                    Height
                  </Form.Label>
                  {input.height.unit === Unit.Distance.FEET && (
                    <>
                      <Col sm={2}>
                        <Form.Control
                          type="number"
                          placeholder="ft"
                          required
                          value={input.height?.value1}
                          onChange={(e) =>
                            setField("height", {
                              ...input.height,
                              value1: e.target.value,
                            })
                          }
                          isInvalid={errors.height ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.height}
                        </Form.Control.Feedback>
                      </Col>

                      <Col sm={2}>
                        <Form.Control
                          type="number"
                          placeholder="in"
                          required
                          value={input.height?.value2}
                          onChange={(e) =>
                            setField("heightSec", {
                              ...input.height,
                              value2: e.target.value,
                            })
                          }
                          isInvalid={errors.heightSec ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.heightSec}
                        </Form.Control.Feedback>
                      </Col>
                    </>
                  )}
                  {input.height.unit === Unit.Distance.CENTIMETER && (
                    <>
                      <Col sm={4}>
                        <Form.Control
                          type="number"
                          placeholder="cm"
                          required
                          value={input.height?.value1}
                          onChange={(e) =>
                            setField("height", {
                              ...input.height,
                              value1: e.target.value,
                            })
                          }
                          isInvalid={errors.height ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.height}
                        </Form.Control.Feedback>
                      </Col>
                    </>
                  )}
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      required
                      onChange={(e) =>
                        setField("height", {
                          ...input.height,
                          unit: e.target.value,
                        })
                      }
                    >
                      <option value={Unit.Distance.FEET}>
                        {Unit.Distance.FEET} {"/ inches"}
                      </option>
                      <option value={Unit.Distance.CENTIMETER}>
                        {Unit.Distance.CENTIMETER}
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                {/* <Button
                  variant="danger"
                  type="button"
                  onClick={(e) => handleReset(e)}
                >
                  Reset
                </Button> */}
              </Form>
            </CardBody>
          </Card>
          {results && <OutputPanel results={results} />}
        </div>
      </div>
    </>
  );
}

// output panel
const OutputPanel = ({ results }) => {
  return (
    <Card className="sticky-top-panel">
      <CardHeader title={"Results"}></CardHeader>
      <CardBody>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Weight</th>
              <th>Imperial</th>
              <th>Metric</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ideal healthy weight</td>
              <td>
                <Badge variant="success">
                  {results.healthyWeight.lb} {UnitDefinition.Mass.pound.abbr}
                </Badge>
              </td>
              <td>
                <Badge variant="info">
                  {results.healthyWeight.kg} {UnitDefinition.Mass.kilogram.abbr}
                </Badge>
              </td>
            </tr>
            <tr>
              <td>Ideal weight range</td>
              <td>
                <Badge variant="success">
                  {results.healthyWeightRangeLabel.lb}{" "}
                  {UnitDefinition.Mass.pound.abbr}
                </Badge>
              </td>
              <td>
                <Badge variant="info">
                  {results.healthyWeightRangeLabel.kg}{" "}
                  {UnitDefinition.Mass.kilogram.abbr}
                </Badge>
              </td>
            </tr>
            {results.isBMIDataAvailable && (
              <tr>
                <td>Healthy weight range</td>
                <td>
                  <Badge variant="success">
                    {results.healthyBMIRangeLabel.lb}{" "}
                    {UnitDefinition.Mass.pound.abbr}
                  </Badge>
                </td>
                <td>
                  <Badge variant="info">
                    {results.healthyBMIRangeLabel.kg}{" "}
                    {UnitDefinition.Mass.kilogram.abbr}
                  </Badge>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
