/**
 * Remove a class from an element
 */
export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

/**
 * Adds a class class to an element
 * @param {*} ele
 * @param {*} cls
 */
export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

/**
 * Converts given path name to absolute URL
 * @param {*} pathname
 * @returns string
 */
export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;
