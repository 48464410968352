// allows to set the meta tags associated to the page
import { useEffect } from "react";

export function useMetaTags(title, desc, keywords) {
  useEffect(() => {
    const prevTitle = document.title;
    if (desc != null) {
      setDescription(desc);
    }
    if (keywords != null) {
      setKeywords(keywords);
    }
    setTitle(title);
    return () => {
      document.title = prevTitle;
    };
  });
}
// set the page title
const setTitle = (title) => {
  const el = document.querySelector("title");
  //el.innerText = `${el.text} | ${title}`;
  el.innerText = `${title}`;
};

// set the page meta desc
const setDescription = (desc) => {
  const el = document.querySelector("meta[name='description']");
  el.setAttribute("content", desc);
};

// set the page meta kw
const setKeywords = (keywords) => {
  const el = document.querySelector("meta[name='keywords']");
  el.setAttribute("content", keywords);
};
