// @flow
import React from "react";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls/Card";
import AdSense from "react-adsense";
import ReactHtmlParser from "react-html-parser";

/**
 * Represnts a Post Page
 * @returns
 */

type Props = {
  title: string,
  contents: string,
};

const Post = ({ title, contents }: Props): React$MixedElement => {
  return (
    <>
      <AdSense.Google
        client="ca-pub-5467405372637074"
        slot="5556792997"
        style={{ display: "block" }}
        format="auto"
        responsive="true"
        layoutKey="-gw-1+2a-9x+5c"
      />
      <div className="row">
        <div className="col-md-12">
          <Card>
            <CardHeader title={title} />{" "}
            <CardBody>
              <div>{ReactHtmlParser(contents)}</div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Post;
