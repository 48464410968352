// @flow
import type { Problem, Remedy } from "../utils/Constants";
import { ProgressBar } from "react-bootstrap";
import ProblemView from "../components/ProblemView";
import RemedyView from "../components/RemedyView";
import { useSubheader } from "_metronic/layout/index";

import { runApiQuery } from "app/utils/APIFunctions";
import { useMetaTags } from "app/utils/SeoFunction";
import { gql } from "graphql-request";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls/Card";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { recordPageView } from "app/utils/APIFunctions";

const RemedyPagBase = (props): React$MixedElement => {
  const remedyURL = props.location.pathname;
  console.log("RemedyPage" + remedyURL);
  const [remedy: Remedy, setRemedy] = useState(null);
  const [problem: Problem, setProblem] = useState(null);
  const [relatedRemedies: Array<Remedy>, setRelatedRemedies] = useState(null);
  const [remedyGuid: string, setRemedyGuid] = useState(remedyURL.split("/")[2]);

  // check if got referred via search
  const query = new URLSearchParams(props.location.search);
  const guidTypeValue = query.get("guid_type");
  console.log("guidType", guidTypeValue);
  const [guidType: string, setGuidType] = useState(guidTypeValue);

  const suhbeader = useSubheader();
  useMetaTags(
    "Home Remedies",
    "Get relief from common health problem with time tested natural home remedies that simply work"
  );

  const FRAGMENT_REMEDY_FIELDS = gql`
    fragment RemedyFields on Remedy {
      __typename
      guid
      title
      descr
      contents
      tags
      problem {
        guid
        title
        descr
        contents
        tags
      }
      relatedRemedies {
        title
        guid
        seo_guid
      }
    }
  `;
  const GET_REMEDY_QUERY = gql`
    query($guid: ID!) {
      remedyQuery(guid: $guid) {
        ...RemedyFields
      }
    }
    ${FRAGMENT_REMEDY_FIELDS}
  `;

  const GET_REMEDY_QUERY_BY_NODE = gql`
    query($guid: ID!) {
      remedyQuery(node_guid: $guid) {
        ...RemedyFields
      }
    }
    ${FRAGMENT_REMEDY_FIELDS}
  `;
  useEffect(() => {
    console.log("in useEffect");
    const loadRemedy = async () => {
      if (remedy == null) {
        const variables = {
          guid: remedyGuid,
        };
        let data = null;
        if (guidType == null) {
          data = await runApiQuery(GET_REMEDY_QUERY, variables);
        } else {
          data = await runApiQuery(GET_REMEDY_QUERY_BY_NODE, variables);
        }
        //    console.log(data);
        if (data.remedyQuery && data.remedyQuery[0]) {
          setRemedy(data.remedyQuery[0]);
          //console.log(data.remedyQuery[0]);
          setProblem(data.remedyQuery[0].problem);
          setRelatedRemedies(data.remedyQuery[0].relatedRemedies);
        }
      }
    };
    loadRemedy();
    if (remedy && suhbeader.title !== remedy.title) {
      suhbeader.setTitle(remedy.title);

      let breadcrumbs;

      breadcrumbs = [
        {
          title: "Problems",
          pathname: "/problem/home",
        },
      ];
      suhbeader.setBreadcrumbs(breadcrumbs);
    }
    recordPageView(remedyURL);
  }, [remedyGuid, remedyURL, remedy, suhbeader, GET_REMEDY_QUERY, props]);

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          {remedy && (
            <RemedyView key={remedy.guid} remedy={remedy}></RemedyView>
          )}

          {problem && <ProblemView problem={problem}></ProblemView>}
        </div>
        <div className="col-md-3 sticky-top-panel">
          {problem && (
            <Card>
              <CardHeader title={"Problem"} toolbar={() => {}}>
                <ProgressBar
                  variant={"success"}
                  animated
                  now={100}
                  style={{ height: "5px", width: "100%" }}
                />
              </CardHeader>
              <CardBody>
                <div>
                  <Link to={`/problem/${problem.guid}/${problem.seo_guid}`}>
                    {problem.title}
                  </Link>
                </div>
              </CardBody>
            </Card>
          )}

          <Card className="sticky-top-panel">
            <CardHeader title={"Related Remedies"} toolbar={() => {}}>
              <ProgressBar
                variant={"warning"}
                animated
                now={100}
                style={{ height: "5px", width: "100%" }}
              />
            </CardHeader>
            <CardBody>
              <ul>
                {relatedRemedies?.map((remedy, index) => {
                  remedy.index = index;

                  return (
                    <li key={remedy.guid}>
                      {" "}
                      <Link
                        to={`/remedy/${remedy.guid}/${remedy.seo_guid}`}
                        onClick={() => {
                          setRemedyGuid(remedy.guid);
                          setRemedy(null);
                          setGuidType(null);
                        }}
                        key={remedy.guid}
                      >
                        {remedy.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export const RemedyPage = withRouter(RemedyPagBase);
