// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "app/components/screener/CompanyScreenActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "_metronic/_helpers";
import * as uiHelpers from "app/components/screener/CompanyScreenUIHelpers";
//import * as columnFormatters from "./column-formatters";
import {
  BuyRatingColumnFormatter,
  RatingColumnFormatter,
  StatusColumnFormatter,
} from "app/components/screener/BuyRatingColumnFormatter";
import { Pagination } from "_metronic/_partials/controls";
import { useCompanyScreenUIContext } from "app/components/screener/CompanyScreenUIContext";
import {
  ScoreColumnFormatter,
  TypeColumnFormatter,
} from "app/components/screener/ScoreColumnFormatter";
import { ActionsColumnFormatter } from "app/components/screener/ActionsColumnFormatter";
import { MarketCapColumnFormatter } from "app/components/screener/MarketCapColumnFormatter";
import { CompanyNameColumnFormatter } from "app/components/screener/CompanyNameColumnFormatter";

export function CompanyScreenTable() {
  // Customers UI Context
  const companyScreenUIContext = useCompanyScreenUIContext();
  const companyScreenUIProps = useMemo(() => {
    return {
      ids: companyScreenUIContext.ids,
      setIds: companyScreenUIContext.setIds,
      queryParams: companyScreenUIContext.queryParams,
      setQueryParams: companyScreenUIContext.setQueryParams,
    };
  }, [companyScreenUIContext]);

  // Getting current state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.companies }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("Loading companies");
    // clear selections list
    companyScreenUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchCompanies(companyScreenUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyScreenUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "rank",
      text: "Rank",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "symbol",
      text: "Symbol",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: CompanyNameColumnFormatter,
    },
    {
      dataField: "title",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "sector",
      text: "Sector",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "industry",
      text: "Industry",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "marketCapClass",
      text: "Class",
      sort: true,
      sortCaret: sortCaret,
      formatter: MarketCapColumnFormatter,
    },
    {
      dataField: "score",
      text: "Quant Score",
      sort: true,
      sortCaret: sortCaret,
      formatter: ScoreColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "buyRating",
      text: "Buy Rating",
      sort: true,
      sortCaret: sortCaret,
      formatter: BuyRatingColumnFormatter,
    },
    {
      dataField: "action",
      text: "Report",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: companyScreenUIProps.openEditCustomerDialog,
        openDeleteCustomerDialog: companyScreenUIProps.openDeleteCustomerDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: companyScreenUIProps.queryParams.pageSize,
    page: companyScreenUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="guid"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  companyScreenUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: companyScreenUIProps.ids,
                //   setIds: companyScreenUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
