// @flow
import { Breadcrumb } from "react-bootstrap";
import { Notice } from "_metronic/_partials/controls/Notice";
import { useSubheader } from "_metronic/layout/index";

import React from "react";
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useMetaTags } from "app/utils/SeoFunction";
import QuestionGrid from "../components/QuestionGrid";
import { titleCase } from "app/utils/UtilFunctions";

export const QuestionListPage = (props): React$MixedElement => {
  console.log("props", props);
  const pageURL = props.location.pathname;
  const suhbeader = useSubheader();
  let {
    categoryId,
    categorySeoGuid,
    state,
    tagSeoGuid,
    orderBy,
  } = props.match.params;

  useMetaTags("Questions");

  useEffect(() => {
    ReactGA.pageview(pageURL);
    suhbeader.setTitle("Questions");
  }, [suhbeader]);

  function getTitle() {
    let title = "All";
    if (categorySeoGuid) {
      title = titleCase(categorySeoGuid);
    }
    if (state == "open") return "/Open";
    else if (state == "upcoming") title += "/Upcoming";
    else if (state == "closed") title += "/Closed";
    else if (state == "resolved") title += "/Resolved";

    return title;
  }

  return (
    <>
      {/* <Breadcrumb>
        <Breadcrumb.Item href="/question/home">home</Breadcrumb.Item>
        {categorySeoGuid && (
          <Breadcrumb.Item active href={`/question/category/{categorySeoGuid}`}>
            {categorySeoGuid}
          </Breadcrumb.Item>
        )}
        {state && (
          <Breadcrumb.Item active href={`/question/state/{state}`}>
            {state}
          </Breadcrumb.Item>
        )}
      </Breadcrumb> */}

      <QuestionGrid {...props.match.params}></QuestionGrid>
    </>
  );
};
