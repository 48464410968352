import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useEffect } from "react";
import { confirmRegistration } from "app/modules/Auth/_redux/authCrud";
import { AlertMessage } from "app/AlertMessage";

const initialValues = {
  email: "",
};

export function ConfirmRegistration(props) {
  const location = useLocation();
  const token = queryString.parse(location.search)["token"];
  console.log("token", token);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    // make the call to confirm the registration
    if (!token || isConfirmed) {
      return;
    }
    // setIsLoading(false);
    // setIsConfirmed(true);
    // return;
    confirmRegistration(token)
      .then(({ data: { code, message } }) => {
        console.log("when reponsed received");
        setIsLoading(false);
        setMessage(message);
        if (code != 0) {
          setIsConfirmed(false);
        } else {
          setIsConfirmed(true);
        }
      })
      .catch(() => {
        console.log("when error received");
        setIsLoading(false);
        setMessage(
          "Failed to confirm your registration. Please try again later."
        );
      });
  }, []);

  console.log("check token");
  if (!token) {
    return (
      <AlertMessage type="danger">
        <div>
          Registration confirmation token not found in the URL. <br />
          Please check you email and click the registration confirm link sent in
          the email.
          <br /> Thank you
        </div>
      </AlertMessage>
    );
  }
  if (isLoading) {
    console.log("in loading");
    return (
      <AlertMessage type="info">
        <div>
          Confirming your registration, Please wait...
          <span className="ml-3 spinner spinner-white"></span>
        </div>
      </AlertMessage>
    );
  }
  return (
    <>
      {isConfirmed && (
        <>
          <AlertMessage type="success">
            <div>
              Great, your registration has been confirmed!
              <br /> Thank you
            </div>
            <Link to={"/auth/login"}>Login now</Link>
          </AlertMessage>
        </>
      )}
      {!isLoading && !isConfirmed && (
        <>
          <AlertMessage type="danger">
            <div>Faield to confirm your registration.</div>
            <div>${message}</div>
          </AlertMessage>
        </>
      )}
    </>
  );
}
